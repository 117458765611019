import React from 'react';
import { Row, Col, Button, Form, Card } from 'react-bootstrap';
import { FaPlus, FaTimes } from 'react-icons/fa';

const EditableList = ({ agentDetails, pros, setPros, cons, setCons, analysisLoading }) => {
  const handleAddItem = (listSetter) => {
    listSetter((prevList) => [{ text: '', isEditing: true }, ...prevList]);
  };

  const handleRemoveItem = (index, listSetter) => {
    listSetter((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleEditItem = (index, listSetter) => {
    listSetter((prevList) =>
      prevList.map((item, i) =>
        i === index ? { ...item, isEditing: !item.isEditing } : item
      )
    );
  };

  const handleChangeItem = (index, newText, listSetter) => {
    listSetter((prevList) =>
      prevList.map((item, i) => (i === index ? { ...item, text: newText } : item))
    );
  };

  const handleBlur = (index, listSetter, item) => {
    if (item.text.trim() === '') {
      handleRemoveItem(index, listSetter);
    } else {
      handleEditItem(index, listSetter);
    }
  };

  const handleKeyPress = (e, index, listSetter, items) => {
    if (e.key === 'Enter') {
      if (items[index].text.trim() === '') {
        handleRemoveItem(index, listSetter);
      } else {
        handleEditItem(index, listSetter);
        if (!items.some(item => item.text === '' && item.isEditing)) {
          handleAddItem(listSetter);
        }
      }
    }
  };

  const isAddButtonDisabled = (items) => {
    return analysisLoading || items.some(item => item.text === '' || item.text === 'Click to edit');
  };

  const renderList = (items, setItems) => (
    <div>
      <Button
        variant="primary"
        style={{ backgroundColor: agentDetails.agent_colour, minWidth: '90px', border: 'none' }}
        onClick={() => handleAddItem(setItems)}
        disabled={isAddButtonDisabled(items)}
      >
        <FaPlus style={{ color: agentDetails.agent_button_colour, marginRight: '8px', position: 'relative', bottom: '2px' }} />
        <span style={{ color: agentDetails.agent_button_colour }}>Add</span>
      </Button>
      <ul className="list-unstyled mt-3">
        {items.map((item, index) => (
          <li key={index} className="mb-2">
            {item.isEditing ? (
              <Form.Control
                type="text"
                value={item.text}
                autoFocus
                onChange={(e) => handleChangeItem(index, e.target.value, setItems)}
                onBlur={() => handleBlur(index, setItems, item)}
                onKeyPress={(e) => handleKeyPress(e, index, setItems, items)}
                disabled={analysisLoading}
              />
            ) : (
              <Card
                onClick={() => !analysisLoading && handleEditItem(index, setItems)}
                style={{
                  cursor: analysisLoading ? 'not-allowed' : 'pointer',
                  transition: 'background-color 0.2s',
                  backgroundColor: item.isHovered ? '#f8f9fa' : 'white',
                }}
                onMouseEnter={() => setItems(prevItems => prevItems.map((itm, i) => i === index ? { ...itm, isHovered: true } : itm))}
                onMouseLeave={() => setItems(prevItems => prevItems.map((itm, i) => i === index ? { ...itm, isHovered: false } : itm))}
              >
                <Card.Body
                  className="d-flex justify-content-between align-items-center"
                  style={{ padding: '3px 12px 3px 12px' }}
                >
                  <span style={item.text ? {} : { color: '#6c757d', fontStyle: 'italic' }}>
                    {item.text || 'Click to edit'}
                  </span>
                  <Button
                    variant="link"
                    size="sm"
                    style={{ color: agentDetails.agent_button_colour, marginLeft: '5px', paddingRight: '0px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveItem(index, setItems);
                    }}
                    disabled={analysisLoading}
                  >
                    <FaTimes />
                  </Button>
                </Card.Body>
              </Card>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div style={{ marginTop: '20px' }}>
      <Row>
        <Col xs={12} md={6}>
          <p style={{ marginBottom: '10px' }}>Pros</p>
          {renderList(pros, setPros)}
        </Col>
        <Col xs={12} md={6}>
          <p style={{ marginBottom: '10px' }}>Cons</p>
          {renderList(cons, setCons)}
        </Col>
      </Row>
    </div>
  );
};

export default EditableList;