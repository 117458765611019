import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner, Image, Card, Container, Row, Col } from 'react-bootstrap';
import { FaUpload, FaImages, FaCheck, FaTimes } from 'react-icons/fa';
import { handleTokenAndPostRequest } from '../utils/utils';

const ImageUploadButton = ({ agentDetails, onUploadSuccess, onDeleteSuccess, onShowSignInModal, propertyId, analysisLoading, uploadedImageUrls = [] }) => {
  const [showModal, setShowModal] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [uploadedImages, setUploadedImages] = useState(uploadedImageUrls);
  const [localToServerMap, setLocalToServerMap] = useState({});

  useEffect(() => {
    setUploadedImages(uploadedImageUrls);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const modalBody = document.querySelector('.modal-body');
      if (modalBody) {
        const viewportHeight = window.innerHeight;
        modalBody.style.maxHeight = `${viewportHeight - 100}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    const jwt_token = localStorage.getItem('jwt_token');
    if (!jwt_token) {
      onShowSignInModal();
      return;
    }

    setShowModal(true);
  };
  const handleFileSelect = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = 'image/*';
  
    const removeInput = () => {
      if (input.parentNode) {
        document.body.removeChild(input);
      }
    };
  
    input.onchange = async (event) => {
      try {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
          // Display images immediately
          const fileUrls = files.map(file => URL.createObjectURL(file));
          setUploadedImages((prevImages) => [...prevImages, ...fileUrls]);
  
          // Map local URLs to null initially
          const newMap = {};
          fileUrls.forEach(url => {
            newMap[url] = null;
          });
          setLocalToServerMap((prevMap) => ({ ...prevMap, ...newMap }));
  
          // Upload and process images in the background
          handleUploadFiles(files, fileUrls);
        }
      } catch (error) {
        console.error('Error during file selection or upload:', error);
      } finally {
        removeInput(); // Ensure input is removed after use
      }
    };
  
    document.body.appendChild(input);
    input.click();
  
    // Use a timeout to ensure the input is removed after the dialog is closed
    setTimeout(removeInput, 1000);
  };
  
  const handleUploadFiles = async (files, fileUrls) => {
    setLoadingUpload(true);
    await uploadFiles(files, fileUrls);
  };

  const uploadFiles = async (files, fileUrls) => {
    try {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));
      formData.append('property_id', propertyId);

      const response = await handleTokenAndPostRequest(`${window.api_host}/upload-images`, formData, {
        'Content-Type': 'multipart/form-data',
      });

      const uploadedUrls = response.data.uploaded_urls;

      // Replace local URLs with server URLs
      setUploadedImages((prevImages) => {
        const newImages = [...prevImages];
        fileUrls.forEach((fileUrl, index) => {
          const fileUrlIndex = newImages.indexOf(fileUrl);
          if (fileUrlIndex !== -1) {
            newImages[fileUrlIndex] = uploadedUrls[index];
          }
        });
        return newImages;
      });

      // Update the local to server map
      setLocalToServerMap((prevMap) => {
        const newMap = { ...prevMap };
        fileUrls.forEach((fileUrl, index) => {
          newMap[fileUrl] = uploadedUrls[index];
        });
        return newMap;
      });
      setLoadingUpload(false);

      await onUploadSuccess(uploadedUrls);
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteImage = (url) => {
    // Determine if the URL is a local or server URL
    const serverUrl = localToServerMap[url] || url;

    // Remove image immediately
    setUploadedImages((prevImages) => prevImages.filter((image) => image !== url && image !== serverUrl));

    // Remove from local to server map
    setLocalToServerMap((prevMap) => {
      const newMap = { ...prevMap };
      delete newMap[url];
      return newMap;
    });

    // Call delete image API in the background
    handleTokenAndPostRequest(`${window.api_host}/delete-image`, { url: serverUrl, property_id: propertyId })
      .then(() => onDeleteSuccess(serverUrl))
      .catch((error) => console.error('Error deleting image:', error));
  };

  const renderThumbnails = () => {
    const maxVisibleImages = 2;
    const visibleImages = uploadedImages.slice(-maxVisibleImages);
    const extraImagesCount = Math.max(uploadedImages.length - maxVisibleImages, 0);

    return (
      <div className="d-flex flex-wrap">
        {visibleImages.map((url, index) => (
          <Image
            key={index}
            src={url}
            thumbnail
            style={{ width: '30%', maxWidth: '110px', height: '80px', objectFit: 'cover', margin: '5px' }}
          />
        ))}
        {extraImagesCount > 0 && (
          <Card style={{ width: '30%', maxWidth: '110px', height: '80px', margin: '5px', textAlign: 'center' }}>
            <Card.Body className="d-flex align-items-center justify-content-center">
              <Card.Text>+ {extraImagesCount} more</Card.Text>
            </Card.Body>
          </Card>
        )}
      </div>
    );
  };

  const renderFullImageList = () => (
    <Container fluid>
      <Row className="d-flex flex-wrap">
        {uploadedImages.map((url, index) => (
          <Col
            key={index}
            xs={4}
            sm={3}
            md={2}
            style={{
              padding: '5px',
              boxSizing: 'border-box',
              position: 'relative',
            }}
          >
            <Image
              src={url}
              thumbnail
              style={{ width: '100%', height: '70px', objectFit: 'cover' }}
            />
            {!loadingUpload && ( // Only show the delete icon when not uploading
            <FaTimes
            onClick={() => handleDeleteImage(url)}
            style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                cursor: 'pointer',
                color: agentDetails.agent_button_colour,
                backgroundColor: 'white', // Match the card's background
                border: '1px solid #dee2e6', // Match the card's border
                borderRadius: '50%', // Make it circular
                padding: '3px',
                fontSize: '1.4em', // 20% larger
                boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)', // Match the card's shadow
            }}
            />
            )}
          </Col>
        ))}
      </Row>
    </Container>
  );

  return (
    <>
      <Button
        style={{
          backgroundColor: agentDetails.agent_colour,
          color: agentDetails.agent_button_colour,
          border: 'none',
          minWidth: '50px',
          marginBottom: '10px',
        }}
        disabled={analysisLoading}
        onClick={handleButtonClick}
      >
        <FaImages style={{ marginRight: '8px' }} />
        Upload & Edit Images
      </Button>

      {renderThumbnails()}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        size="lg"
        style={{ zIndex: 9999 }}
        fullscreen="sm-down"
      >
        <Modal.Body
          style={{
            overflowY: 'auto',
            ...(window.innerWidth >= 576 && { maxHeight: 'calc(100vh - 150px)' }),
          }}
        >
            {uploadedImages.length === 0 && (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <div className="text-center mb-3">
                Upload your images to improve the accuracy of your valuation.
                </div>
            </div>
            )}
          {renderFullImageList()}
        </Modal.Body>
        <Modal.Footer style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', width: '100%' }}>
          <div className="d-flex justify-content-between w-100">
            <Button
              style={{
                backgroundColor: agentDetails.agent_colour,
                color: agentDetails.agent_button_colour,
                border: 'none',
                width: '48%',
              }}
              onClick={handleFileSelect}
              disabled={loadingUpload}
            >
              {loadingUpload ? (
                <>
                  <Spinner animation="border" size="sm" /> Uploading
                </>
              ) : (
                <>
                  <FaImages style={{ marginRight: '8px' }} />
                  Select Images
                </>
              )}
            </Button>
            <Button
              style={{
                backgroundColor: agentDetails.agent_colour,
                color: agentDetails.agent_button_colour,
                border: 'none',
                width: '48%',
              }}
              onClick={handleCloseModal}
            >
              <FaCheck style={{ marginRight: '8px' }} />
              Done
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageUploadButton;