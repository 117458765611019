import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, ButtonGroup, Button, Spinner, Card, Carousel, Form, Modal, Alert } from 'react-bootstrap';
import Select from 'react-select';
import ListingMap from './ListingMap'; // Import the ListingMap component
import { FaBed, FaBath, FaCar, FaListUl, FaMap, FaAngleUp, FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import { handleTokenAndPostRequest, formatCurrency } from '../utils/utils';
import ValueDisplayGroup from './ValueDisplayGroup';
import SignInModal from './SignInModal'; // Import the SignInModal component

const dateRangeOptions = [
    { value: '7', label: 'Last week' },
    { value: '30', label: 'Last month' },
    { value: '90', label: 'Last 3 months' },
    { value: '183', label: 'Last 6 months' },
    { value: '365', label: 'Last year' },
    { value: '730', label: 'Last 2 years' },
];

const propertyTypeOptions = [
    { value: 'House', label: 'House' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Townhouse', label: 'Townhouse' },
    { value: 'Vacant Land', label: 'Vacant Land' },
    { value: 'Villa', label: 'Villa' },
    { value: 'Acreage Semi Rural', label: 'Acreage Semi Rural' },
    { value: 'Rural', label: 'Rural' },
    { value: 'Semi Detached', label: 'Semi Detached' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'New House Land', label: 'New House Land' },
    { value: 'New Apartments', label: 'New Apartments' },
    { value: 'Block Of Units', label: 'Block Of Units' },
    { value: 'Studio', label: 'Studio' },
    { value: 'Terrace', label: 'Terrace' }
];

const bedroomOptions = [
    { value: '0', label: '0 bedrooms' },
    { value: '1', label: '1 bedroom' },
    { value: '2', label: '2 bedrooms' },
    { value: '3', label: '3 bedrooms' },
    { value: '4', label: '4 bedrooms' },
    { value: '5', label: '5 bedrooms' },
    { value: '6+', label: '6+ bedrooms' }
];

const ComparableProperties = ({ suburb, defaultPropertyType, defaultBedrooms, agentDetails, title, subtitle, modalContent }) => {
    const [activeComparableModeButton, setActiveComparableModeButton] = useState('Sold');
    const [selectedDateRange, setSelectedDateRange] = useState(dateRangeOptions[3]); // Default to 6 months
    const [comparablesIncludeOnMarket, setComparablesIncludeOnMarket] = useState(false);
    const [comparablesIncludeOffMarket, setComparablesIncludeOffMarket] = useState(true);
    const [comparablesLoading, setComparablesLoading] = useState(false);
    const [recentSoldData, setRecentSoldData] = useState([]);
    const [lowestPrice, setLowestPrice] = useState(null);
    const [showFixedButtonGroup, setShowFixedButtonGroup] = useState(false);
    const [medianPrice, setMedianPrice] = useState(null);
    const [highestPrice, setHighestPrice] = useState(null);
    const [viewMode, setViewMode] = useState('List'); // New state for view mode
    const [excludedProperties, setExcludedProperties] = useState([]); // State for excluded properties
    const [showCard, setShowCard] = useState(false); // State for card visibility
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [showScrollToTop, setShowScrollToTop] = useState(false); // State for scroll to top button visibility
    const [comparableCount, setComparableCount] = useState(null);
    const [showAlert, setShowAlert] = useState(false); // State for alert visibility
    const [signInModalShow, setSignInModalShow] = useState(false); // State for SignInModal visibility
    const [modalSuccessTrigger, setModalSuccessTrigger] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(defaultPropertyType ? [{ value: defaultPropertyType, label: defaultPropertyType }] : []);
    const [selectedBedrooms, setSelectedBedrooms] = useState(defaultBedrooms ? [{ value: defaultBedrooms === 6 ? '6+' : defaultBedrooms.toString(), label: defaultBedrooms === 1 ? '1 bedroom' : `${defaultBedrooms} bedrooms` }] : []);
    const handleComparableModeButtonClick = (mode) => {
        const jwtToken = localStorage.getItem('jwt_token');
        const paramToken = localStorage.getItem('param_token');
        if (jwtToken || paramToken) {
            setActiveComparableModeButton(mode);
        } else {
            setSignInModalShow(true);
        }
    };

    const handleViewModeToggle = (mode) => {
        setViewMode(mode);
    };

    const calculatePriceStats = (properties) => {
        const prices = properties
            .filter(property => !excludedProperties.includes(property.id))
            .map(property => property.price)
            .filter(price => price !== null && price !== undefined);
        if (prices.length > 0) {
            const sortedPrices = prices.sort((a, b) => a - b);
            const medianPrice = sortedPrices.length % 2 === 0
                ? (sortedPrices[sortedPrices.length / 2 - 1] + sortedPrices[sortedPrices.length / 2]) / 2
                : sortedPrices[Math.floor(sortedPrices.length / 2)];
            const lowestPrice = sortedPrices[0];
            const highestPrice = sortedPrices[sortedPrices.length - 1];
    
            return { lowestPrice, medianPrice, highestPrice, count: prices.length };
        } else {
            return { lowestPrice: null, medianPrice: null, highestPrice: null, count: 0 };
        }
    };
    useEffect(() => {
        console.log('useEffect');
        const fetchData = async () => {
            try {
                setComparablesLoading(true);
                console.log('fetchData');
                console.log(selectedPropertyTypes);
                console.log(selectedBedrooms);
    
                const propertyTypes = selectedPropertyTypes ? selectedPropertyTypes.map(option => option.value) : [];
                const bedrooms = selectedBedrooms ? selectedBedrooms.map(option => option.value) : [];
                console.log(propertyTypes);
                console.log(bedrooms);
                const response = await handleTokenAndPostRequest(
                    `${window.api_host}/listing-search`,
                    {
                        suburb: suburb,
                        agent_slug: agentDetails.landing_page_slug,
                        listing_type: activeComparableModeButton,
                        days_in_past: selectedDateRange?.value,
                        include_on_market: comparablesIncludeOnMarket,
                        include_off_market: comparablesIncludeOffMarket,
                        excluded_properties: excludedProperties,
                        property_types: propertyTypes,
                        bedrooms: bedrooms,
                    },
                    {
                        'Content-Type': 'application/json',
                    }
                );
                console.log(response);
                const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
                setRecentSoldData(data['results']);
    
                // Remove any properties from excludedProperties which are not in the results
                const resultIds = data['results'].map(property => property.id);
                setExcludedProperties(prevState => prevState.filter(id => resultIds.includes(id)));
    
                const { lowestPrice, medianPrice, highestPrice, count } = calculatePriceStats(data['results']);
                setLowestPrice(lowestPrice);
                setMedianPrice(medianPrice);
                setHighestPrice(highestPrice);
                setComparableCount(count);
            } catch (error) {
                console.error("Error fetching suburb data", error);
            } finally {
                setComparablesLoading(false);
            }
        };
    
        if (suburb && activeComparableModeButton && selectedDateRange && agentDetails) {
            fetchData();
        }
    }, [
        suburb, 
        activeComparableModeButton, 
        selectedDateRange, 
        comparablesIncludeOnMarket, 
        comparablesIncludeOffMarket, 
        agentDetails,
        modalSuccessTrigger, // Add this dependency to trigger the useEffect when modal is successfully closed
        selectedPropertyTypes,
        selectedBedrooms
    ]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1200);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    


    useEffect(() => {
        const { lowestPrice, medianPrice, highestPrice, count } = calculatePriceStats(recentSoldData);
        setLowestPrice(lowestPrice);
        setMedianPrice(medianPrice);
        setHighestPrice(highestPrice);
        setComparableCount(count);
        setShowAlert(count < 5 && count > 0); // Show alert if less than 5 comparables

    }, [
        excludedProperties, 
        recentSoldData, 
        activeComparableModeButton, 
        selectedDateRange, 
        comparablesIncludeOnMarket, 
        comparablesIncludeOffMarket
    ]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setShowScrollToTop(true);
            } else {
                setShowScrollToTop(false);
            }

            // Show card only when scrolled away from the estimate section
            const estimateSection = document.getElementById('estimate-section');
            if (estimateSection && window.scrollY > estimateSection.offsetTop + estimateSection.offsetHeight && estimateSection.offsetTop > 0) {
                setShowCard(true);
            } else {
                setShowCard(false);
            }

            // Show fixed button group when scrolled past the original position
            const buttonGroup = document.getElementById('button-group');
            console.log(window.scrollY, buttonGroup.offsetTop, buttonGroup.offsetHeight, window.innerHeight);
            if (buttonGroup && window.scrollY + window.innerHeight > buttonGroup.offsetTop + buttonGroup.offsetHeight && buttonGroup.offsetTop > 0) {
                setShowFixedButtonGroup(true);
            } else {
                setShowFixedButtonGroup(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleCheckboxChange = (propertyId) => {
        const jwtToken = localStorage.getItem('jwt_token');
        const paramToken = localStorage.getItem('param_token');
        if (jwtToken || paramToken) {
            setExcludedProperties(prevState => 
                prevState.includes(propertyId) 
                    ? prevState.filter(id => id !== propertyId) 
                    : [...prevState, propertyId]
            );
        } else {
            setSignInModalShow(true);
        }
    };

    const handleModalSuccess = () => {
        setSignInModalShow(false);
        setModalSuccessTrigger(prev => !prev); // Toggle the state to trigger the useEffect
    };

    const renderListView = () => (
        <div>
            {recentSoldData && (
                <Row>
                    {recentSoldData.map((property, index) => {
                        const mediaArray = JSON.parse(property.media); // Parse the media JSON string
                        return (
                            <Col key={index} xs={12} md={6} lg={4} className="mt-3">
                                <Card style={{ height: '100%' }}>
                                    <div style={{ borderTopLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem', overflow: 'hidden' }}>
                                        <Carousel interval={null} style={{ height: '200px' }}>
                                            {mediaArray.map((mediaItem, mediaIndex) => (
                                                <Carousel.Item key={mediaIndex}>
                                                    <img
                                                        className="d-block w-100"
                                                        src={mediaItem.url}
                                                        alt={`Slide ${mediaIndex}`}
                                                        style={{ height: '200px', objectFit: 'cover', borderTopLeftRadius: '0.25rem', borderTopRightRadius: '0.25rem' }}
                                                    />
                                                </Carousel.Item>
                                            ))}
                                        </Carousel>
                                    </div>
                                    <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <Card.Title style={{ fontSize: '1rem', marginBottom: '5px'}}>{property.display_address_short}<br/>{property.suburb}, {property.postcode}</Card.Title>
                                        <Card.Text>
                                            <strong>Price:</strong> {property.display_price}<br />
                                            <strong>Days on Market:</strong> {property.days_on_market}<br />
                                            <FaBed /> {property.bedrooms} <span style={{ marginRight: '10px' }}></span>
                                            <FaBath /> {property.bathrooms || 0} <span style={{ marginRight: '10px' }}></span>
                                            <FaCar /> {property.carspaces || 0}
                                        </Card.Text>
                                        <Form.Check 
                                            type="checkbox" 
                                            label="Exclude from comparable estimate" 
                                            checked={excludedProperties.includes(property.id)}
                                            onChange={() => handleCheckboxChange(property.id)}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            )}
        </div>
    );

    return (
        <div style={{ marginBottom: isMobile ? '112px' : '58px' }}>
            <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>
                {title}
                <Button 
                    variant="link" 
                    onClick={() => setShowModal(true)} 
                    style={{ 
                        padding: '5px', 
                        marginLeft: '5px', 
                        marginBottom: '5px', 
                        borderRadius: '50%', 
                        color: agentDetails.agent_button_colour
                    }}
                >
                    <FaQuestionCircle style={{fontSize: '1.1rem'}}/>
                </Button>
            </h5>
            <p>{subtitle}</p>
            {showAlert && (
                <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                    <FaInfoCircle style={{ marginRight: '8px', marginBottom: '3px' }} /> The price range is based on less than 5 properties, more comparables will provide a more accurate estimate.
                </Alert>
            )}
            <Row style={{ alignItems: 'flex-start' }}>
                <Col xs={6} md={6} style={{ paddingTop: '0.3rem' }}>
                    <ButtonGroup style={{ width: '100%', marginBottom: '0.6rem' }}>
                        {['Sold', 'Rent'].map((mode) => (
                            <Button
                                key={mode}
                                variant="secondary"
                                onClick={() => handleComparableModeButtonClick(mode)}
                                style={{
                                    backgroundColor: activeComparableModeButton === mode ? agentDetails.agent_colour : '#d1d1d1',
                                    color: activeComparableModeButton === mode ? agentDetails.agent_button_colour : agentDetails.agent_button_colour,
                                }}
                            >
                                {mode}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Col>

                <Col xs={6} md={6} style={{ paddingTop: '0.3rem', marginBottom: '0.8rem' }}>
                    <Select
                        options={dateRangeOptions}
                        placeholder="Date Range"
                        onChange={(selectedOption) => {
                            const jwtToken = localStorage.getItem('jwt_token');
                            const paramToken = localStorage.getItem('param_token');
                            if (jwtToken || paramToken) {
                                setSelectedDateRange(selectedOption);
                            } else {
                                setSignInModalShow(true);
                            }
                        }}
                        value={selectedDateRange}
                        styles={{
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9998, // Ensure the dropdown appears above other elements
                            }),
                        }}                        
                    />
                </Col>                
                <Col xs={12} md={6} style={{ paddingTop: '0.3rem', marginBottom: '0.8rem' }}>
                    <Select
                        options={propertyTypeOptions}
                        isMulti
                        placeholder="Property Types"
                        closeMenuOnSelect={false}
                        onChange={(selectedOptions) => {
                            const jwtToken = localStorage.getItem('jwt_token');
                            const paramToken = localStorage.getItem('param_token');
                            if (jwtToken || paramToken) {
                                setSelectedPropertyTypes(selectedOptions);
                            } else {
                                setSignInModalShow(true);
                            }
                        }}
                        value={selectedPropertyTypes}
                        styles={{
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9998, // Ensure the dropdown appears above other elements
                            }),
                        }}
                    />
                </Col>
                <Col xs={12} md={6} style={{ paddingTop: '0.3rem', marginBottom: '0.8rem' }}>
                    <Select
                        options={bedroomOptions}
                        isMulti
                        placeholder="Bedrooms"
                        closeMenuOnSelect={false}
                        onChange={(selectedOptions) => {
                            const jwtToken = localStorage.getItem('jwt_token');
                            const paramToken = localStorage.getItem('param_token');
                            if (jwtToken || paramToken) {
                                setSelectedBedrooms(selectedOptions);
                            } else {
                                setSignInModalShow(true);
                            }
                        }}
                        value={selectedBedrooms}
                        styles={{
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9998, // Ensure the dropdown appears above other elements
                            }),
                        }}
                    />
                </Col>
                <Col xs={12} id="estimate-section" style={{ display: 'flex', minHeight: '140px', flexDirection: 'column', justifyContent: 'center', maxWidth: '100%', marginBottom: '0px', marginTop: '0px' }}>
                    {comparablesLoading && recentSoldData && agentDetails ? (
                        <></>
                    ) : (
                        comparableCount === 0 ? (
                            <>
                                <p style={{ textAlign: 'center', marginBottom: '5px' }}>No comparable properties found.</p>
                                <p style={{ textAlign: 'center', fontSize: '0.8rem'}}>
                                    ({recentSoldData.length} results, {excludedProperties.length} excluded)
                                </p>
                            </>
                        ) : (
                            <>
                                <ValueDisplayGroup
                                    lowerValue={lowestPrice !== null ? formatCurrency(lowestPrice) : ''}
                                    midValue={medianPrice !== null ? formatCurrency(medianPrice) : ''}
                                    upperValue={highestPrice !== null ? formatCurrency(highestPrice) : ''}
                                    midColor={agentDetails.agent_button_colour}
                                    listingType={activeComparableModeButton}
                                    showTitle={true}
                                />
                                <p style={{ textAlign: 'center', fontSize: '0.8rem', marginBottom: '5px'}}>
                                    Based on {comparableCount} comparables <br/>({recentSoldData.length} results, {excludedProperties.length} excluded)
                                </p>
                            </>
                        )
                    )}
                </Col>
                <Col xs={12} style={{ paddingTop: '0.3rem' }}>
                <div id="button-group" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    { comparablesLoading && recentSoldData && agentDetails ? (
                        <></>
                    ) : (
                        <ButtonGroup style={{ marginBottom: '0px', position: showFixedButtonGroup ? 'fixed' : 'static', bottom: showFixedButtonGroup ? isMobile ? '66px': '10px' : 'auto', zIndex: 9997 }}>
                            {['List', 'Map'].map((mode) => (
                                <Button
                                    key={mode}
                                    variant="secondary"
                                    onClick={() => handleViewModeToggle(mode)}
                                    style={{
                                        backgroundColor: viewMode === mode ? agentDetails.agent_colour : '#d1d1d1',
                                        color: viewMode === mode ? agentDetails.agent_button_colour : agentDetails.agent_button_colour,
                                    }}
                                >
                                    {mode === 'List' ? <FaListUl style={{ marginRight: '5px' }} /> : <FaMap style={{ marginRight: '5px' }} />}
                                    {mode}
                                </Button>
                            ))}
                        </ButtonGroup>                        

                    )}

                    </div>                    
                </Col>                

            </Row>
            {comparablesLoading && recentSoldData && agentDetails ? (
                <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '50px', minHeight: '600px', flexDirection: 'column', alignItems: 'center' }}>
                    <Spinner animation="border" />
                </div>
            ) : (
                recentSoldData.length === 0 ? (
                    <>
                    </>
                ) : (
                    viewMode === 'List' ? renderListView() : <ListingMap listingData={recentSoldData} agentDetails={agentDetails} />
                )
            )}
            {showCard && viewMode === 'List' && !comparablesLoading && (
                <Card
                    style={{
                        position: 'fixed',
                        top: isMobile ? '66px' : '95px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1,
                        width: '80%',
                        maxWidth: '300px',
                        padding: '0px',
                        fontSize: '0.875rem',
                        height: 'auto',
                        zIndex: 9998
                    }}
                >
                    <Card.Body style={{ padding: '10px'}}>
                        {lowestPrice === null && medianPrice === null && highestPrice === null ? (
                            <p style={{ textAlign: 'center', marginBottom: '0px' }}>No comparable properties found.</p>
                        ) : (
                            <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                                    <p style={{ marginBottom: '0.25rem', fontSize: '0.65rem', color: '#7e8594' }}>LOW</p>
                                    <p style={{ marginBottom: '0.25rem', fontSize: '1.15rem' }}>{formatCurrency(lowestPrice)}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                                    <p style={{ marginBottom: '0.25rem', fontSize: '0.65rem', color: '#7e8594' }}>MID</p>
                                    <p style={{ marginBottom: '0.25rem', fontSize: '1.4rem', fontWeight: 'bold', color: agentDetails.agent_button_colour }}>{formatCurrency(medianPrice)}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                                    <p style={{ marginBottom: '0.25rem', fontSize: '0.65rem', color: '#7e8594' }}>HIGH</p>
                                    <p style={{ marginBottom: '0.25rem', fontSize: '1.15rem' }}>{formatCurrency(highestPrice)}</p>
                                </div>
                            </div>
                            <p style={{ textAlign: 'center', marginTop: '0px', marginBottom: '0px', fontSize: '0.8rem' }}>
                                Based on {comparableCount} comparables
                            </p>
                            </>
                        )}
                    </Card.Body>
                </Card>
            )}
            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>How Comparable Analysis Works</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {showScrollToTop && (
                <Button
                    variant="primary"
                    onClick={() => window.scrollTo({ top: 300, behavior: 'smooth' })}
                    style={{
                        position: 'fixed',
                        bottom: isMobile ? '66px': '10px',
                        right: '12px',
                        zIndex: 1,
                        borderRadius: '6px',
                        padding: '10px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: agentDetails.agent_colour,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'none',
                        color: agentDetails.agent_button_colour,
                    }}
                >
                    <FaAngleUp />
                </Button>
            )}
            {agentDetails && (
                <SignInModal 
                    show={signInModalShow} 
                    onHide={() => setSignInModalShow(false)}
                    onSuccess={handleModalSuccess}
                    agentDetails={agentDetails}
                />
            )}
        </div>
    );
};

export default ComparableProperties;