import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ButtonGroup, Image, Spinner, Modal, ProgressBar, Alert, Tabs, Tab, Card, Nav, Breadcrumb, OverlayTrigger, Tooltip, Carousel, FormCheck, Toast, ToastContainer} from 'react-bootstrap';
import MainNav from '../components/MainNav';
import SignInModal from '../components/SignInModal';
import ShareModal from '../components/ShareModal';
import ValueDisplayGroup from '../components/ValueDisplayGroup';
import Plot from 'react-plotly.js';
import { APIProvider, Map, AdvancedMarker, InfoWindow, useAdvancedMarkerRef, Pin } from '@vis.gl/react-google-maps';
import './Styles.css';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle, FaStar, FaSave, FaQuestionCircle, FaShareAlt, FaMinus, FaPlus, FaBed, FaBath, FaCar, FaArrowUp, FaArrowDown,FaChartLine, FaBalanceScale, FaHistory, FaCheck, FaSearch } from 'react-icons/fa';
import { handleTokenAndPostRequest, formatCurrency } from '../utils/utils';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { set } from 'lodash';
import ComparableProperties from '../components/ComparableProperties';
import EditableList from '../components/EditableList';
import ImageUploadButton from '../components/ImageUploadButton';

const propertyTypeOptions = [
    { value: 'House', label: 'House' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Townhouse', label: 'Townhouse' },
    { value: 'Vacant Land', label: 'Vacant Land' },
    { value: 'Villa', label: 'Villa' },
    { value: 'Acreage Semi Rural', label: 'Acreage Semi Rural' },
    { value: 'Rural', label: 'Rural' },
    { value: 'Semi Detached', label: 'Semi Detached' },
    { value: 'Duplex', label: 'Duplex' },
    { value: 'New House Land', label: 'New House Land' },
    { value: 'New Apartments', label: 'New Apartments' },
    { value: 'Block Of Units', label: 'Block Of Units' },
    { value: 'Studio', label: 'Studio' },
    { value: 'Terrace', label: 'Terrace' }
];


        
const proFeatureOptions = [
    { value: 'SecureParking', label: 'Secure Parking' },
    { value: 'BuiltInWardrobes', label: 'Built In Wardrobes' },
    { value: 'AirConditioning', label: 'Air Conditioning' },
    { value: 'Ensuite', label: 'Ensuite' },
    { value: 'Gas', label: 'Gas' },
    { value: 'Heating', label: 'Heating' },
    { value: 'Dishwasher', label: 'Dishwasher' },
    { value: 'Floorboards', label: 'Floorboards' },
    { value: 'Study', label: 'Study' },
    { value: 'SwimmingPool', label: 'Swimming Pool' },
    { value: 'Bath', label: 'Bath' },
    { value: 'InternalLaundry', label: 'Internal Laundry' },
    { value: 'Shed', label: 'Shed' },
    { value: 'Intercom', label: 'Intercom' },
    { value: 'FullyFenced', label: 'Fully Fenced' },
    { value: 'NorthFacing', label: 'North Facing' },
    { value: 'SolarPanels', label: 'Solar Panels' },
    { value: 'GroundFloor', label: 'Ground Floor' },
    { value: 'AlarmSystem', label: 'Alarm System' },
    { value: 'Gym', label: 'Gym' },
    { value: 'BalconyDeck', label: 'Balcony Deck' },
    { value: 'PetsAllowed', label: 'Pets Allowed' },
    { value: 'GardenCourtyard', label: 'Garden Courtyard' },
    { value: 'WaterViews', label: 'Water Views' },
    { value: 'Furnished', label: 'Furnished' },
    { value: 'CityViews', label: 'City Views' },
    { value: 'SeparateDiningRoom', label: 'Separate Dining Room' },
    { value: 'RainwaterStorageTank', label: 'Rainwater Storage Tank' },
    { value: 'BroadbandInternetAccess', label: 'Broadband Internet Access' },
    { value: 'CableOrSatellite', label: 'Cable Or Satellite' },
    { value: 'TennisCourt', label: 'Tennis Court' },
    { value: 'SolarHotWater', label: 'Solar Hot Water' },
    { value: 'DoubleGlazedWindows', label: 'Double Glazed Windows' },
    { value: 'WallCeilingInsulation', label: 'Wall Ceiling Insulation' },
    { value: 'OutdoorSpa', label: 'Outdoor Spa' },
    { value: 'IndoorSpa', label: 'Indoor Spa' },
    { value: 'EnergyEfficientAppliances', label: 'Energy Efficient Appliances' },
    { value: 'WaterEfficientAppliances', label: 'Water Efficient Appliances' },
    { value: 'GreywaterSystem', label: 'Greywater System' },
    { value: 'WaterEfficientFixtures', label: 'Water Efficient Fixtures' },

    // AI Generated Features
    { value: 'SmartHomeTechnology', label: 'Smart Home Technology' },
    { value: 'HomeOffice', label: 'Home Office' },
    { value: 'WineCellar', label: 'Wine Cellar' },
    { value: 'HomeTheater', label: 'Home Theater' },
    { value: 'RooftopTerrace', label: 'Rooftop Terrace' },
    { value: 'PrivateElevator', label: 'Private Elevator' },
    { value: 'ButlersPantry', label: 'Butler\'s Pantry' },
    { value: 'Library', label: 'Library' },
    { value: 'Fireplace', label: 'Fireplace' },
    { value: 'Mudroom', label: 'Mudroom' },
    { value: 'WalkInPantry', label: 'Walk-In Pantry' },
    { value: 'OutdoorKitchen', label: 'Outdoor Kitchen' }
];
    

const conFeatureOptions = [
    { value: 'HighMaintenance', label: 'High Maintenance' },
    { value: 'NoisyNeighborhood', label: 'Noisy Neighborhood' },
    { value: 'PoorPublicTransport', label: 'Poor Public Transport' },
    { value: 'LimitedParking', label: 'Limited Parking' },
    { value: 'OldAppliances', label: 'Old Appliances' },
    { value: 'SmallRooms', label: 'Small Rooms' },
    { value: 'NoOutdoorSpace', label: 'No Outdoor Space' },
    { value: 'HighCrimeRate', label: 'High Crime Rate' },
    { value: 'FloodProneArea', label: 'Flood Prone Area' },
    { value: 'ExpensiveUtilities', label: 'Expensive Utilities' },
    { value: 'PoorInsulation', label: 'Poor Insulation' },
    { value: 'NoisyNeighbors', label: 'Noisy Neighbors' },
    { value: 'LimitedNaturalLight', label: 'Limited Natural Light' },
    { value: 'FarFromAmenities', label: 'Far From Amenities' },
    { value: 'OldRoof', label: 'Old Roof' },
    { value: 'FoundationIssues', label: 'Foundation Issues' },
    { value: 'PestProblems', label: 'Pest Problems' },
    { value: 'NoGarage', label: 'No Garage' },
    { value: 'SteepDriveway', label: 'Steep Driveway' },
    { value: 'PoorSchoolDistrict', label: 'Poor School District' },
    { value: 'LimitedStorage', label: 'Limited Storage' },
    { value: 'OutdatedInterior', label: 'Outdated Interior' },
    { value: 'NoAirConditioning', label: 'No Air Conditioning' },
    { value: 'NoHeating', label: 'No Heating' },
    { value: 'SharedWalls', label: 'Shared Walls' },
    { value: 'NoPrivacy', label: 'No Privacy' },
    { value: 'LimitedInternetOptions', label: 'Limited Internet Options' }
];

const PropertyProfile = () => {
    const [agentDetails, setAgentDetails] = useState(null);
    const [propertyData, setPropertyData] = useState(null);
    const [propertyAnalysisData, setPropertyAnalysisData] = useState(null);
    const { property, agent_slug: initial_agent_slug } = useParams();
    console.log('property:', property);
    console.log('initial_agent_slug:', initial_agent_slug);
    const [propertyId, setPropertyId] = useState(property.split('-').pop())
    console.log('propertyId:', propertyId);
    console.log('property.split().pop()', property.split('-').pop())
    const agent_slug = initial_agent_slug || 'property-insights';
    const [suburb, setSuburb] = useState(null);
    const [postcode, setPostcode] = useState(null);
    const [bedrooms, setBedrooms] = useState(null);
    const [bathrooms, setBathrooms] = useState(null);
    const [carspaces, setCarspaces] = useState(null);    
    const [propertyType, setPropertyType] = useState(propertyTypeOptions[0]);
    const [landArea, setLandArea] = useState(null);
    const [propertyNotes, setPropertyNotes] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [state, setState] = useState(null);
    const [mediaUrls, setMediaUrls] = useState([]);
    const [analysedBedrooms, setAnalysedBedrooms] = useState(null);
    const [analysedProFeatures, setAnalysedProFeatures] = useState([]);
    const [analysedConFeatures, setAnalysedConFeatures] = useState([]);
    const [analysedBathrooms, setAnalysedBathrooms] = useState(null);
    const [analysedCarspaces, setAnalysedCarspaces] = useState(null);    
    const [analysedPropertyType, setAnalysedPropertyType] = useState(propertyTypeOptions[0]);
    const [analysedLandArea, setAnalysedLandArea] = useState(null);
    const [analysedMediaUrls, setAnalysedMediaUrls] = useState([]);

    const [savedBedrooms, setSavedBedrooms] = useState(null);
    const [savedBathrooms, setSavedBathrooms] = useState(null);
    const [savedCarspaces, setSavedCarspaces] = useState(null);    
    const [savedPropertyType, setSavedPropertyType] = useState(propertyTypeOptions[0]);
    const [savedLandArea, setSavedLandArea] = useState(null);
    const [savedProFeatures, setSavedProFeatures] = useState([]);
    const [savedConFeatures, setSavedConFeatures] = useState([]);
    const [savedMediaUrls, setSavedMediaUrls] = useState([]);
    const [savedUploadedImageUrls, setSavedUploadedImageUrls] = useState([]);

    const [analysisNeeded, setAnalysisNeeded] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [proFeatures, setProFeatures] = useState([]);
    const [conFeatures, setConFeatures] = useState([]);
    const [tabKey, setTabKey] = useState('estimates');
    const [analysisLoading, setAnalysisLoading] = useState(false);
    const [propertyLoading, setPropertyLoading] = useState(false);
    const [recentSoldData, setRecentSoldData] = useState(null);
    const[firstAnalysisComplete, setFirstAnalysisComplete] = useState(false);
    const [valuationId, setValuationId] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
    const [modalShow, setModalShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [shareUrl, setShareUrl] = useState(null);
    
    const [showSaveToast, setShowSaveToast] = useState(false);
    const [isSaved, setIsSaved] = useState(false);    
    const [isSavingValuation, setIsSavingValuation] = useState(false); 
    const [estimationInfoShowModal, setEstimationInfoShowModal] = useState(false);


    const [showShareModal, setShowShareModal] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    
    const [uploadedImageUrls, setUploadedImageUrls] = useState(null);

    // update propertyId when property changes
    useEffect(() => {
        console.log('updating propertyId from -> to:', propertyId, property.split('-').pop())
        setPropertyId(property.split('-').pop())
    }, [property]);


    const handleDeletedUrl = (url) => {
        // Remove the deleted URL from the uploadedImageUrls
        setUploadedImageUrls((prevUploadedImageUrls) => prevUploadedImageUrls.filter((imageUrl) => imageUrl !== url));
        console.log('Deleted URL:', url);
    
        // Update the mediaUrls state by removing the deleted URL
        setMediaUrls((prevMediaUrls) => prevMediaUrls.filter((mediaUrl) => mediaUrl !== url));
    };
    const handleUploadedUrls = async (urls) => {
        // Append new URLs to the existing uploadedImageUrls
        setUploadedImageUrls((prevUploadedImageUrls) => [...prevUploadedImageUrls, ...urls]);
        console.log('Uploaded URLs:', urls);
    
        // Update the mediaUrls state directly
        setMediaUrls((prevMediaUrls) => [...urls, ...(prevMediaUrls || [])]);
    
        setActiveIndex(0);
    
        try {
            // Make the API request to process images
            const response = await handleTokenAndPostRequest(
                `${window.api_host}/process-images`,
                {
                    property_id: propertyId, // Ensure propertyId is available in the scope
                    image_urls: urls
                },
                {
                    'Content-Type': 'application/json',
                }
            );
    
            // Update pros and cons by adding to them
            setProFeatures((prevPros) => [
                ...prevPros,
                ...response.data.pro_features
                    .map(pro => proFeatureOptions.find(option => option.value === pro))
                    .filter(pro => pro !== undefined) // Filter out undefined values
            ]);
            
            setConFeatures((prevCons) => [
                ...prevCons,
                ...response.data.con_features
                    .map(con => conFeatureOptions.find(option => option.value === con))
                    .filter(con => con !== undefined) // Filter out undefined values
            ]);
    
        } catch (error) {
            console.error('Error processing images:', error);
            // Handle error appropriately
        }
    };


    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };    

    const handleShowShareModal = () => setShowShareModal(true);
    
    const handleHideShareModal = () => setShowShareModal(false);
  
    const handleCopySuccess = () => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 5000); // Hide the toast after 5 seconds
    };

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const param_token = params.get('token');
    const valuation_id = params.get('valuation_id');


    const jwt_token = localStorage.getItem('jwt_token');

    const animatedComponents = makeAnimated();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (analysisNeeded && firstAnalysisComplete && !analysisLoading && !propertyLoading) {
            setShowToast(true);
            const timer = setTimeout(() => {
                setShowToast(false);
            }, 5000); // Hide the toast after 5 seconds

            return () => clearTimeout(timer); // Clear the timer if the component unmounts
        }
    }, [analysisNeeded]);
    
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1200);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);    

    useEffect(() => {
        if (valuation_id) {
            setValuationId(valuation_id);
        }
    }, [valuation_id]);    

    useEffect(() => {
        if (agentDetails) {
            document.title = `${agentDetails.agency_name_short} Property Report`;
            document.documentElement.style.setProperty('--nav-link-active-border-color', agentDetails.agent_colour);
            document.documentElement.style.setProperty('--nav-link-active-color', agentDetails.agent_button_colour);            
            document.documentElement.style.setProperty('--nav-link-font-size', isMobile ? '0.8rem' : '1rem');            
        }
    }, [agentDetails]);

    useEffect(() => {
        const fetchAgentDetails = async () => {
          const url = `${window.api_host}/agency`;  // Adjusted to remove specific slug from URL path
      
          try {
            const response = await handleTokenAndPostRequest(
              url,
              {
                agent_slug: agent_slug,  // Include agent_slug in the body
                property: property,
              },
              {
                'Content-Type': 'application/json',
              }
            );
      
            setAgentDetails(response.data);
          } catch (error) {
            console.error('Error:', error);
          }
        };
      
        fetchAgentDetails();
    }, [agent_slug, property]);


    useEffect(() => {
        if (!propertyLoading) {
            console.log("Loading property!");
            setPropertyLoading(true);
            const fetchData = async () => {
                try {
                // take the last part of the URL after the last - character
                    const slug = property.split('-').slice(0, -1).join('-');
                    
                    console.log('valuation_id: ', valuation_id);
                    const response = await handleTokenAndPostRequest(
                        `${window.api_host}/property`,
                        {
                          property_id: property.split('-').pop(),
                          agent_slug: agent_slug,
                          valuation_id: valuation_id,
                          slug: slug
                        },
                        {
                          'Content-Type': 'application/json',
                        }
                    );
                    setPropertyData(response.data);
                    setBedrooms(response.data.bedrooms);
                    setLandArea(response.data.land_area);
                    setBathrooms(response.data.bathrooms);
                    setCarspaces(response.data.carspaces);
                    setLatitude(response.data.latitude);
                    setLongitude(response.data.longitude);
                    setState(response.data.state);
                    setPropertyType(response.data.listing_property_type);
                    setSuburb(response.data.suburb_id);
                    setPostcode(response.data.postcode);
                    setMediaUrls(response.data.media_urls);
                    setUploadedImageUrls(response.data.uploaded_image_urls || []);
                    setProFeatures(
                        response.data.pro_features
                            .map(pro => proFeatureOptions.find(option => option.value === pro))
                            .filter(pro => pro !== undefined) // Filter out undefined values
                    );
                    
                    setConFeatures(
                        response.data.con_features
                            .map(con => conFeatureOptions.find(option => option.value === con))
                            .filter(con => con !== undefined) // Filter out undefined values
                    );

                    setSavedProFeatures(response.data.saved_pro_features.map(pro => proFeatureOptions.find(option => option.value === pro)));
                    setSavedConFeatures(response.data.saved_con_features.map(con => conFeatureOptions.find(option => option.value === con)));
                    setSavedBedrooms(response.data.saved_bedrooms);
                    setSavedLandArea(response.data.saved_land_area);
                    setSavedBathrooms(response.data.saved_bathrooms);
                    setSavedCarspaces(response.data.saved_carspaces);
                    setSavedPropertyType(response.data.saved_property_type);
                    setSavedMediaUrls(response.data.saved_media_urls);
                    setSavedUploadedImageUrls(response.data.saved_uploaded_image_urls);
                    
                    if (response.data.valuation_id){
                        
                        setValuationId(response.data.valuation_id);
                        const currentUrl = new URL(window.location.href);
                        currentUrl.searchParams.set('valuation_id', response.data.valuation_id);
                        navigate(currentUrl.pathname + currentUrl.search, { replace: true });
                    }
                
                } catch (error) {
                    console.log("Error fetching /property data", error);
                } finally {
                    setPropertyLoading(false);
                }
            };
            fetchData();
        }
    }, [property, agent_slug]);

    useEffect(() => {
        if (propertyData) {
            const saveSettings = async () => {
                try {
                    await handleTokenAndPostRequest(
                        `${window.api_host}/save-settings`,
                        {
                            agent_slug: agent_slug,
                            event_type: 'property_analysis', // or any other event type you need
                            address_text: propertyData.address_text,
                            property_id: propertyId
                        },
                        {
                            'Content-Type': 'application/json',
                        }
                    );
                    // We don't await the response or handle it, as we don't care about it
                } catch (error) {
                    console.error("Error saving settings", error);
                }
            };
    
            saveSettings();
        }
    }, [propertyData]);

    useEffect(() => {
        if (!analysisLoading && propertyData) {
            const fetchData = async () => {
                try {
                    // last part of url
                    
                    setAnalysisLoading(true);
                    const sold_date = propertyData.sold_date;
                    const sold_price = propertyData.sold_price;
                    const rented_date = propertyData.rented_date;
                    const rented_price = propertyData.rented_price;
                    console.log('Running analysis')
                    console.log('proFeatures:', proFeatures);
                    console.log('conFeatures:', conFeatures);
                    const response = await handleTokenAndPostRequest(
                        `${window.api_host}/property-analysis`,
                        {
                          property_id: propertyId,
                          bedrooms: bedrooms,
                          bathrooms: bathrooms,
                          carspaces: carspaces,
                          land_area: landArea,
                          property_type: propertyType,
                          pro_features: proFeatures.map(feature => feature.value),
                          con_features: conFeatures.map(feature => feature.value),
                          property_notes: propertyNotes,
                          last_sold_date: sold_date,
                          last_sold_price: sold_price,
                          last_rented_date: rented_date,
                          last_rented_price: rented_price,
                          state: state,
                          postcode: postcode,
                          latitude: latitude,
                          longitude: longitude,
                          agent_slug: agent_slug,
                          media_urls: mediaUrls,
                          page: 'PropertyProfile',
                          uploaded_image_urls: uploadedImageUrls
                        },
                        {
                          'Content-Type': 'application/json',
                        }
                    );
                    setPropertyAnalysisData(response.data);

                    setAnalysedBathrooms(bathrooms);
                    setAnalysedBedrooms(bedrooms);
                    setAnalysedProFeatures(proFeatures);
                    setAnalysedConFeatures(conFeatures);
                    setAnalysedCarspaces(carspaces);
                    setAnalysedLandArea(landArea);
                    setAnalysedPropertyType(propertyType);
                    setAnalysedMediaUrls(mediaUrls);

                    setAnalysisNeeded(false);

                } catch (error) {
                    console.error("Error fetching property analysis data", error);
                } finally {
                    setAnalysisLoading(false);
                    setFirstAnalysisComplete(true);
                }
            };
            fetchData();
        }
    }, [propertyData]); 


    useEffect(() => {
        // Check if analysis is needed based on property attributes
        if (
            bedrooms !== analysedBedrooms ||
            bathrooms !== analysedBathrooms ||
            carspaces !== analysedCarspaces ||
            landArea !== analysedLandArea ||
            propertyType !== analysedPropertyType ||
            !arraysEqual(proFeatures, analysedProFeatures) || // Check if pro features differ
            !arraysEqual(conFeatures, analysedConFeatures) ||   // Check if con features differ
            !arraysEqual(mediaUrls, analysedMediaUrls) // Check if media URLs differ
        ) {
            setAnalysisNeeded(true);
        } else {
            setAnalysisNeeded(false);
        }
    
        // Check if the property data is saved
        if (propertyData) {
            if (
                (savedBedrooms === bedrooms || (savedBedrooms === null && bedrooms === null)) &&
                (savedBathrooms === bathrooms || (savedBathrooms === null && bathrooms === null)) &&
                (savedCarspaces === carspaces || (savedCarspaces === null && carspaces === null)) &&
                (savedLandArea === landArea || (savedLandArea === null && landArea === null)) &&
                (savedPropertyType === propertyType || (savedPropertyType === null && propertyType === null)) &&
                arraysEqual(proFeatures, savedProFeatures) && // Check if pro features are saved
                arraysEqual(conFeatures, savedConFeatures) &&   // Check if con features are saved
                arraysEqual(mediaUrls, savedMediaUrls) // Check if media URLs are saved
            ) {
                setIsSaved(true);
            } else {
                setIsSaved(false);
            }
        }
    
    }, [
        bedrooms,
        bathrooms,
        carspaces,
        propertyType,
        landArea,
        proFeatures, // Add proFeatures to the dependency array
        conFeatures,  // Add conFeatures to the dependency array
        mediaUrls
    ]);
    
    // Helper function to compare arrays
    const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every((value, index) => value === arr2[index]);
    };


    const handleAnalyseProperty = async () => {
        try {
            const jwt_token = localStorage.getItem('jwt_token');
            const param_token = localStorage.getItem('param_token');
            if (!jwt_token && !param_token) {
                setModalShow(true);
                return;
            }
        
            setTabKey('estimates');
            setAnalysisLoading(true);
        
            
            const response = await handleTokenAndPostRequest(
                `${window.api_host}/property-analysis`,
                {
                  property_id: propertyId,
                  bedrooms: bedrooms,
                  bathrooms: bathrooms,
                  carspaces: carspaces,
                  land_area: landArea,
                  property_type: propertyType,
                  pro_features: proFeatures.map(feature => feature.value),
                  con_features: conFeatures.map(feature => feature.value),
                  property_notes: propertyNotes,
                  last_sold_date: propertyData.sold_date,
                  last_sold_price: propertyData.sold_price,
                  last_rented_date: propertyData.rented_date,
                  last_rented_price: propertyData.rented_price,
                  state: state,
                  postcode: postcode,
                  latitude: latitude,
                  longitude: longitude,
                  agent_slug: agent_slug,
                  page: 'PropertyProfile',
                  media_urls: mediaUrls,
                  uploaded_image_urls: uploadedImageUrls
                },
                {
                  'Content-Type': 'application/json',
                }
            );
        
            setPropertyAnalysisData(response.data);
            setAnalysedBathrooms(bathrooms);
            setAnalysedBedrooms(bedrooms);
            setAnalysedProFeatures(proFeatures);
            setAnalysedConFeatures(conFeatures);
            setAnalysedCarspaces(carspaces);
            setAnalysedLandArea(landArea);
            setAnalysedPropertyType(propertyType);
            setAnalysedMediaUrls(mediaUrls);
            setAnalysisNeeded(false);
            return response.data;
        } catch (error) {
            console.error("Error fetching property analysis data", error);
        } finally {
            setAnalysisLoading(false);
        }
    };

    const handleModalSuccess = () => {
        setModalShow(false);
        handleAnalyseProperty();
    }

    useEffect(() => {
        if (agentDetails) {
            document.title = `${agentDetails.agency_name_short} Property Report`;
        }
    }, [agentDetails]);

    if (!agentDetails || !propertyData || propertyLoading) {
    return (
        <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Spinner animation="border" />
        </div>
    );
    }


    const formatPercentChange = (value) => {
        const formattedValue = Math.abs(value);
        if (formattedValue === 0) {
            return 0;
        }
        // if null then return 'N/A'
        if (value === null) {
            return 'N/A';
        }
        return formattedValue;
    };
    
    const PropertyMetric = ({
        title,
        metric_definition,
        lowerValue,
        midValue,
        upperValue,
        percentChangeLastMonth,
        percentChangeYearAgo,
        percentChange3YearsAgo,
        percentChangeLastTransacted,

        estimateLastMonth,
        estimateYearAgo,
        estimate3YearsAgo,
        lastTransactedValue,
        lastTransactedDate,
        lastTransactedLabel,

        midColor,
        time_series_date,
        time_series_value,
        yxaxis_label,
        is_currency,
        showTimeSeries
    }) => {
        const margin_bottom_p = '0.25rem';
    
        return (
            <div style={{ marginBottom: '3rem', padding: '0.5rem', border: '0' }}>
                <ToastContainer 
                    style={{
                        position: 'fixed',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 9998,
                        opacity: 1
                    }}                
                    className={`p-3 ${isMobile ? 'toast-container-mobile' : 'toast-container-desktop'}`}
                    >
                    <Toast onClose={() => setCopySuccess(false)} show={copySuccess} delay={2000} autohide>
                    <Toast.Body className="text-center">Link copied to clipboard</Toast.Body>
                    </Toast>
                </ToastContainer>                
                <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>{title}</h5>
                <p style={{ fontSize: '1rem' }}>{metric_definition}</p>
                <div style={{ padding: 0 }}>
                    <ValueDisplayGroup
                        lowerValue={lowerValue}
                        midValue={midValue}
                        upperValue={upperValue}
                        midColor={midColor}
                        showTitle={false}
                    />
                    {showTimeSeries && (
                    
                    <div style={{ marginTop: '1rem' }}>
                        <Row style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">
                                                    Last Month: {formatCurrency(estimateLastMonth)}
                                                    <br />
                                                    Current: {(midValue)}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From Last Month:
                                                </p>
                                            </OverlayTrigger>
                                        </div>
                                        <strong style={{ color: (percentChangeLastMonth) > 0 ? 'green' : (percentChangeLastMonth) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {(percentChangeLastMonth) > 0 ? (
                                            <>
                                                <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                {formatPercentChange(percentChangeLastMonth)}%
                                            </>
                                        ) : (percentChangeLastMonth) < 0 ? (
                                            <>
                                                <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                {formatPercentChange(percentChangeLastMonth)}%
                                            </>
                                        ) : (
                                            <>
                                                {formatPercentChange(percentChangeLastMonth)}%
                                            </>
                                        )}
                                    </strong>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">
                                                    1 Year Ago: {formatCurrency(estimateYearAgo)}
                                                    <br />
                                                    Current: {(midValue)}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From 1 Year Ago:
                                                </p>
                                            </OverlayTrigger>
                                        </div>                                        
                                        
                                        <strong style={{ color: (percentChangeYearAgo) > 0 ? 'green' : (percentChangeYearAgo) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {(percentChangeYearAgo) > 0 ? (
                                                <>
                                                    <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChangeYearAgo)}%
                                                </>
                                            ) : (percentChangeYearAgo) < 0 ? (
                                                <>
                                                    <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChangeYearAgo)}%
                                                </>
                                            ) : (
                                                <>
                                                    {formatPercentChange(percentChangeYearAgo)}%
                                                </>
                                            )}
                                        </strong>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">
                                                    3 Years Ago: {formatCurrency(estimate3YearsAgo)}
                                                    <br />
                                                    Current: {(midValue)}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From 3 Years Ago:
                                                </p>
                                            </OverlayTrigger>
                                        </div>                                           
                                        
                                        <strong style={{ color: (percentChange3YearsAgo) > 0 ? 'green' : (percentChange3YearsAgo) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {(percentChange3YearsAgo) > 0 ? (
                                                <>
                                                    <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChange3YearsAgo)}%
                                                </>
                                            ) : (percentChange3YearsAgo) < 0 ? (
                                                <>
                                                    <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                    {formatPercentChange(percentChange3YearsAgo)}%
                                                </>
                                            ) : (
                                                <>
                                                    {formatPercentChange(percentChange3YearsAgo)}%
                                                </>
                                            )}
                                        </strong>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} sm={6} md={3}>
                                <Card style={{ margin: '0.5rem 0', textAlign: 'center' }}>
                                    <Card.Body>
                                        <div style={{ display: 'inline-block', cursor: 'pointer', marginBottom: '0.4rem' }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                <Tooltip id="tooltip-comparison">                                                    
                                                    {lastTransactedLabel} Date: {lastTransactedDate ? lastTransactedDate.split('T')[0] : "N/A"}
                                                    <br />
                                                    {lastTransactedLabel} Price: {lastTransactedValue ? formatCurrency(lastTransactedValue) : "N/A"}
                                                    <br />
                                                    Current: {midValue}
                                                </Tooltip>
                                                }
                                            >
                                                <p style={{ marginBottom: margin_bottom_p, color: midColor, borderBottom: '1px dotted black', display: 'inline' }}>
                                                From {lastTransactedLabel}:
                                                </p>
                                            </OverlayTrigger>
                                        </div>                                           
                                                                                
                                        {percentChangeLastTransacted === null ? (
                                            <strong style={{ color: 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                N/A
                                            </strong>
                                        ) : (
                                            <strong style={{ color: (percentChangeLastTransacted) > 0 ? 'green' : (percentChangeLastTransacted) < 0 ? 'red' : 'gray', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {(percentChangeLastTransacted) > 0 ? (
                                                    <>
                                                        <FaArrowUp style={{ color: 'green', marginRight: '0.3rem' }} />
                                                        {formatPercentChange(percentChangeLastTransacted)}%
                                                    </>
                                                ) : (percentChangeLastTransacted) < 0 ? (
                                                    <>
                                                        <FaArrowDown style={{ color: 'red', marginRight: '0.3rem' }} />
                                                        {formatPercentChange(percentChangeLastTransacted)}%
                                                    </>
                                                ) : (
                                                    <>
                                                        {formatPercentChange(percentChangeLastTransacted)}%
                                                    </>
                                                )}
                                            </strong>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    )}
                    {showTimeSeries && (
                    <Plot
                        data={[
                            {
                                x: time_series_date,
                                y: time_series_value,
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: { color: agentDetails.agent_colour },
                            },
                        ]}
                        layout={{
                            xaxis: {
                                title: 'Month',
                                tickformat: '%Y-%m',
                                type: 'date',
                            },
                            yaxis: {
                                title: yxaxis_label,
                                tickprefix: is_currency ? '$' : '',
                                range: [0, Math.max(...time_series_value) * 1.1],
                            },
                            margin: {
                                l: 70,
                                r: 30,
                                t: 40,
                                b: 70,
                                pad: 0
                            },
                            autosize: true,
                            dragmode: false, 
                        }}
                        style={{ width: '100%', padding: '0px' }}
                        config={{
                            displayModeBar: false,
                            staticPlot: false, // Ensure the plot is not static to retain tooltips
                            scrollZoom: false, // Disable scroll zoom
                            doubleClick: false, // Disable double-click zoom
                            doubleClickDelay: 1000, // Increase delay for double-click to avoid accidental zoom
                        }}
                    />
                    )}
                </div>
            </div>
        );
    };

    const renderTabTitle = (title) => (
        <span style={{ display: 'flex', marginRight: '10px', marginLeft: '10px', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
            {title}
        </span>
    );

    const AnimatedProgressBar = () => {
        const [now, setNow] = useState(0);
    
        useEffect(() => {
            const interval = setInterval(() => {
                setNow(prev => {
                    if (prev < 90) {
                        return prev + 1.5; // 90 / (5000ms / 100ms)
                    }
                    clearInterval(interval);
                    return 90;
                });
            }, 100);
    
            return () => clearInterval(interval); // cleanup on component unmount
        }, []);
    
        return (
            <div style={{ display: 'flex', justifyContent: 'center', minHeight: '600px', flexDirection: 'column', alignItems: 'center' }}>
                <ProgressBar animated now={now} style={{ width: '50%', }} variant="light"/>
            </div>
        );
    }
    const handleSaveValuation = async () => {
        const jwt_token = localStorage.getItem('jwt_token');
        if (!jwt_token) {
            setModalShow(true);
            return;
        }
        setIsSavingValuation(true);
    
        let analyse_property_response;
        if (analysisNeeded) {
            analyse_property_response = await handleAnalyseProperty();
        } else {
            analyse_property_response = propertyAnalysisData;
        }
    
        try {
            const url = `${window.api_host}/save-valuation`;
            const response = await handleTokenAndPostRequest(url, {
                property_id: propertyId,
                saved_attributes: analyse_property_response
            });
    
            if (response.status === 200) {
                const valuationId = response.data.valuation_id;
                setValuationId(valuationId);
    
                const currentUrl = new URL(window.location.href);
                currentUrl.searchParams.set('valuation_id', valuationId);
                navigate(currentUrl.pathname + currentUrl.search, { replace: true });
    
                // Set the new saved values
                setSavedBedrooms(bedrooms);
                setSavedLandArea(landArea);
                setSavedBathrooms(bathrooms);
                setSavedCarspaces(carspaces);
                setSavedPropertyType(propertyType);
                setSavedProFeatures(proFeatures);
                setSavedConFeatures(conFeatures);
                setSavedMediaUrls(mediaUrls);
                setSavedUploadedImageUrls(uploadedImageUrls);
    
                setShowSaveToast(true);
                setIsSaved(true);
            } else {
                console.log(`Error: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error saving valuation:', error);
        } finally {
            setIsSavingValuation(false);
        }
    };

    const handleShare = () => {
        const jwt_token = localStorage.getItem('jwt_token');
        if (!isSaved) {
            handleSaveValuation();
        }else{
            setShowShareModal(true);
        }
    };


    return (
        <div>
            {agentDetails && (  
            <SignInModal 
                show={modalShow} 
                onHide={() => setModalShow(false)}
                onSuccess={handleModalSuccess}
                agentDetails={agentDetails}
            />
            )}
        <Container style={{ padding: '12px', maxWidth: '900px' }}>
            {mediaUrls && (
            <Carousel
                activeIndex={activeIndex}
                onSelect={handleSelect}
                style={{ borderRadius: '10px', width: '100%', maxWidth: '900px', margin: '0 auto', marginBottom: '20px' }}
                controls={mediaUrls && mediaUrls.length > 1 && mediaUrls[0] !== null}
                indicators={mediaUrls && mediaUrls.length > 1 && mediaUrls[0] !== null}
                interval={null}
            >
                {(mediaUrls && mediaUrls.length > 0 && mediaUrls[0] !== null ? mediaUrls : ['https://realestate-reports-assets.s3.us-west-2.amazonaws.com/static_assets/no-image.jpg']).map((url, index) => (
                    <Carousel.Item key={index} style={{ height: isMobile ? '250px' : '400px' }}>
                        <img
                            className="d-block w-100"
                            src={url}
                            alt={`Slide ${index}`}
                            style={{
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
            )}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
                <h3 style={{ marginBottom: isMobile ? '10px' : '0px' }}>{propertyData.address_text_short}</h3>
                <div style={{ display: 'flex', flexDirection: 'row', width: isMobile ? '100%' : 'auto' }}>
                    <Button 
                        variant="primary" 
                        onClick={handleSaveValuation} 
                        disabled={ isSavingValuation || analysisLoading || isSaved}
                        style={{ 
                            backgroundColor: agentDetails.agent_colour, 
                            color: agentDetails.agent_button_colour, 
                            borderColor: agentDetails.agent_colour, 
                            width: isMobile ? '50%' : 'auto', 
                            marginBottom: isMobile ? '10px' : '0', 
                            marginRight: isMobile ? '5px' : '10px' 
                        }}
                    >
                        {isSavingValuation ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '5px' }}
                            />
                        ) : isSaved ? (
                            <FaCheck style={{ marginRight: '5px', marginBottom: '3px' }} />
                        ) : (
                            <FaSave style={{ marginRight: '5px', marginBottom: '3px' }} />
                        )}
                            
                        { isSaved ? <span>Valuation Saved</span> : <span>Save Valuation</span> }
                    </Button>
                    <Button 
                        variant="secondary" 
                        disabled={ analysisLoading }
                        onClick={handleShare} 
                        style={{ 
                            width: isMobile ? '50%' : 'auto', 
                            marginBottom: isMobile ? '10px' : '0',
                            marginLeft: isMobile ? '5px' : '0px'
                        }}
                    >
                        <FaShareAlt style={{ marginRight: '2px', marginBottom: '3px' }} /> Share
                    </Button>
                    {agentDetails && <ShareModal agentDetails={agentDetails} show={showShareModal} isSavingValuation={isSavingValuation} analysisLoading={analysisLoading} onHide={handleHideShareModal} onCopySuccess={handleCopySuccess} shareUrl={window.location.href} />}
                </div>
            </div>
            <ToastContainer
                style={{
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 9998,
                    opacity: 1
                }}
                className={`p-3 ${isMobile ? 'toast-container-mobile' : 'toast-container-desktop'}`}
            >
                <Toast show={showSaveToast} onClose={() => setShowSaveToast(false)} style={{ opacity: 1 }} delay={5000} bg='light' autohide>
                    <Toast.Body style={{ textAlign: 'center' }}>
                    Valuation saved successfully. View your valuations in {' '}
                    <Link to={`/my-properties/${agent_slug}`} style={{ fontWeight: 'bold', color: agentDetails.agent_button_colour }}>
                        My Properties.
                    </Link>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>
            <Container style={{ maxWidth: '900px',  minHeight: '600px'}}>
            <Tabs activeKey={tabKey} variant='underline' onSelect={(k) => setTabKey(k)} className="mb-3" >
            <Tab eventKey="estimates" title={renderTabTitle("Estimates")}>
            <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>Estimation Tool
            <Button 
                variant="link" 
                onClick={() => setEstimationInfoShowModal(true)} 
                style={{ 
                    padding: '5px', 
                    marginLeft: '5px', 
                    marginBottom: '5px', 
                    borderRadius: '50%', 
                    color: agentDetails.agent_button_colour
                }}
            >
                <FaQuestionCircle style={{fontSize: '1.1rem'}}/>
            </Button>            
            </h5>

            <Modal style={{ zIndex: 9999 }} centered show={estimationInfoShowModal} onHide={() => setEstimationInfoShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>How the Valuation Tool Works</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        To provide an accurate estimate, please update the property details. Our AI regression model needs this information to make a calculation.
                    </p>
                    <ol>
                        <li>Update the property details.</li>
                        <li>Our AI model analyses all prior sales and rental data.</li>
                        <li>The model learns the relationship between each variable (e.g., land area, number of bedrooms) and the sold price, rental price, or days on market.</li>
                        <li>It uses these relationships to make an estimate. For example, the model learns that larger land areas generally mean higher property prices, and this relationship varies by suburb.</li>
                    </ol>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEstimationInfoShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>            
            <p style={{ fontSize: '1rem' }}>Perform your own appraisal using our valuation tool. The more data you add in, the more accurate your estimates will be.</p>

            {showAlert && (
                    <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                        <FaInfoCircle style={{ marginRight: '8px', marginBottom: '3px' }} /> Please review and update any missing or incorrect property details and images below before analysing. Only you can see these changes.
                    </Alert>
                )}
                {agentDetails && propertyData && (
                    <>
                        { uploadedImageUrls && (

                            <ImageUploadButton
                                agentDetails={agentDetails}
                                onUploadSuccess={handleUploadedUrls}
                                onDeleteSuccess={handleDeletedUrl}
                                onShowSignInModal={() => setModalShow(true)}    
                                propertyId={propertyId}  
                                analysisLoading={analysisLoading}
                                uploadedImageUrls={uploadedImageUrls}                      
                            />
                        )}
                        <Row xs={2} sm={1} md={3} className="g-4" style={{ marginTop: '5px'}}>
                        <Col style={{ }}>
                            <Form.Label style={{ width: '100%' }}>
                                Bedrooms
                                <div style={{ marginTop: '10px', display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
                                    <div style={{ paddingRight: '20px'}}>
                                        <Button 
                                            style={{ 
                                                backgroundColor: agentDetails.agent_colour, 
                                                color: agentDetails.agent_button_colour, 
                                                border: 'none', 
                                                borderRadius: '100%' 
                                            }} 
                                            disabled={analysisLoading}
                                            onClick={() => setBedrooms(Math.max(0, parseInt(bedrooms || 0) - 1))}
                                        >
                                            <FaMinus style={{ fontSize: '0.8em', marginBottom: '3px' }}/>
                                        </Button>
                                    </div>
                                    <div>
                                        {bedrooms === null ? 0 : bedrooms} <FaBed style={{ fontSize: '1.2em', marginBottom: '3px' }} />
                                    </div>
                                    <div style={{ paddingLeft: '20px'}}>
                                        <Button 
                                            style={{ 
                                                backgroundColor: agentDetails.agent_colour, 
                                                color: agentDetails.agent_button_colour, 
                                                border: 'none', 
                                                borderRadius: '100%' 
                                            }} 
                                            disabled={analysisLoading}
                                            onClick={() => setBedrooms(parseInt(bedrooms || 0) + 1)}
                                        >
                                            <FaPlus style={{ fontSize: '0.8em', marginBottom: '3px' }}/>
                                        </Button>
                                    </div>
                                </div>
                            </Form.Label>
                        </Col>
                        <Col>
                            <Form.Label style={{ width: '100%' }}>
                                Bathrooms
                                <div style={{ marginTop: '10px', display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
                                    <div style={{ paddingRight: '20px'}}>
                                        <Button 
                                            style={{ 
                                                backgroundColor: agentDetails.agent_colour, 
                                                color: agentDetails.agent_button_colour, 
                                                border: 'none', 
                                                borderRadius: '100%' 
                                            }} 
                                            disabled={analysisLoading}
                                            onClick={() => setBathrooms(Math.max(0, parseInt(bathrooms || 0) - 1))}
                                        >
                                            <FaMinus style={{ fontSize: '0.8em', marginBottom: '3px' }}/>
                                        </Button>
                                    </div>
                                    <div>
                                        {bathrooms === null ? 0 : bathrooms} <FaBath style={{ fontSize: '1.2em', marginBottom: '3px' }} />
                                    </div>
                                    <div style={{ paddingLeft: '20px'}}>
                                        <Button 
                                            style={{ 
                                                backgroundColor: agentDetails.agent_colour, 
                                                color: agentDetails.agent_button_colour, 
                                                border: 'none', 
                                                borderRadius: '100%'
                                            }} 
                                            disabled={analysisLoading}
                                            onClick={() => setBathrooms(parseInt(bathrooms || 0) + 1)}
                                        >
                                            <FaPlus style={{ fontSize: '0.8em', marginBottom: '3px' }}/>
                                        </Button>
                                    </div>
                                </div>
                            </Form.Label>
                        </Col>
                        <Col style={{ }}>
                            <Form.Label style={{ width: '100%' }}>
                                Carspaces
                                <div style={{ marginTop: '10px', display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row' }}>
                                    <div style={{ paddingRight: '20px'}}>
                                        <Button 
                                            style={{ 
                                                backgroundColor: agentDetails.agent_colour, 
                                                color: agentDetails.agent_button_colour, 
                                                border: 'none', 
                                                borderRadius: '100%' 
                                            }} 
                                            disabled={analysisLoading}
                                            onClick={() => setCarspaces(Math.max(0, parseInt(carspaces || 0) - 1))}
                                        >
                                            <FaMinus style={{ fontSize: '0.8em', marginBottom: '3px' }}/>
                                        </Button>
                                    </div>
                                    <div>
                                        {carspaces === null ? 0 : carspaces} <FaCar style={{ fontSize: '1.2em', marginBottom: '3px' }} />
                                    </div>
                                    <div style={{ paddingLeft: '20px'}}>
                                        <Button 
                                            style={{ 
                                                backgroundColor: agentDetails.agent_colour, 
                                                color: agentDetails.agent_button_colour, 
                                                border: 'none', 
                                                borderRadius: '100%'
                                            }} 
                                            disabled={analysisLoading}
                                            onClick={() => setCarspaces(parseInt(carspaces || 0) + 1)}
                                        >
                                            <FaPlus style={{ fontSize: '0.8em', marginBottom: '3px' }}/>
                                        </Button>
                                    </div>
                                </div>
                            </Form.Label>
                        </Col>
                            <Col>
                                <Form.Label>Property Type</Form.Label>
                                { propertyType && (
                                    <Select 
                                        options={propertyTypeOptions} 
                                        components={animatedComponents}
                                        closeMenuOnSelect={true}
                                        styles={{
                                            // Add custom styles if needed
                                        }}
                                        onChange={e => setPropertyType(e.value)}
                                        value={propertyTypeOptions.find(option => option.value === propertyType)}
                                        defaultValue={propertyTypeOptions.find(option => option.value === propertyType)}
                                        isDisabled={analysisLoading} // Disable the input if analysisLoading is true
                                    />
                                )}               
                            </Col>
                            <Col>
                                <Form.Label>Land Area (m²)</Form.Label>
                                <Form.Control 
                                    type="number" 
                                    value={landArea} 
                                    onChange={(e) => setLandArea(e.target.value)} 
                                    placeholder="Land Area (m²)" 
                                    disabled={analysisLoading} // Disable the input if analysisLoading is true
                                />
                            </Col>
                        </Row>
                        {/* {agentDetails && (
                        <EditableList
                            agentDetails={agentDetails}
                            pros={pros}
                            setPros={setPros}
                            cons={cons}
                            setCons={setCons}
                            analysisLoading={analysisLoading}
                        />
                        )} */}
                        <Row >
                            <Col xs={12} md={6} style={{marginTop: '20px'}}>
                                <Form.Label>Pros</Form.Label>
                                <CreatableSelect 
                                    options={proFeatureOptions} 
                                    isMulti
                                    components={animatedComponents}
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    styles={{
                                    }}
                                    onChange={setProFeatures}
                                    value={proFeatures}
                                    isDisabled={analysisLoading} 
                                    isClearable={false}
                                />
                            </Col>
                            <Col xs={12} md={6} style={{marginTop: '20px'}}>
                                <Form.Label>Cons</Form.Label>
                                <CreatableSelect 
                                    options={conFeatureOptions} 
                                    isMulti
                                    components={animatedComponents}
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    styles={{
                                    }}
                                    onChange={setConFeatures}
                                    value={conFeatures}
                                    isDisabled={analysisLoading} 
                                    isClearable={false}
                                />
                            </Col>
                        </Row>
                        {/* <Row xs={1} sm={1} md={1} className="g-4" style={{ marginTop: '0px' }}>
                            <Col>
                                <Form.Label>Property Description</Form.Label>
                                
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    value={propertyNotes}
                                    onChange={(e) => setPropertyNotes(e.target.value)}
                                    placeholder="Add your own notes on the condition of the property. We will use your notes to adjust the valuation calculation. Eg: Renovated kitchen, new tile flooring installed, poor condition interior etc." 
                                />
                            </Col>
                        </Row> */}
                        {/* Analyse Property Button */}
                        <Button 
                            variant="primary" 
                            style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour, marginTop: '20px', width: '100%' }}
                            onClick={handleAnalyseProperty}
                            disabled={analysisLoading || !analysisNeeded}
                        >
                            {analysisLoading ? (
                                <span>
                                    <Spinner animation="border" size="sm" style={{ marginRight: '5px' }}/> Loading...
                                </span>
                            ) : (
                                !analysisNeeded ? (
                                    <span>
                                        <FaCheck style={{ marginRight: '5px' }} /> Analysis Complete
                                    </span>
                                ) : (
                                    <span>
                                        <FaSearch style={{ marginRight: '5px' }} /> Analyse Property
                                    </span>
                                )
                            )}
                        </Button>
                    </>
                )}                
                {analysisLoading ? (
                    // <div style={{ display: 'flex', justifyContent: 'center', minHeight: '600px', flexDirection: 'column', alignItems: 'center' }}>
                    //     <Spinner animation="border" />
                    // </div>
                    <AnimatedProgressBar />                    
                ) : (
                    propertyAnalysisData && agentDetails && (
                        <div style={{ fontSize: isMobile ? '0.8rem' : 'initial', marginTop: '20px' }}>
                                <PropertyMetric
                                    title="Valuation Estimate"
                                    metric_definition="The estimated value of the property based on recent comparable sales data."
                                    lowerValue={formatCurrency(propertyAnalysisData.valuation_lower)}
                                    midValue={formatCurrency(propertyAnalysisData.valuation)}
                                    upperValue={formatCurrency(propertyAnalysisData.valuation_upper)}
                                    midColor={agentDetails.agent_button_colour}
                                    time_series_date={propertyAnalysisData.time_series.reporting_month}
                                    time_series_value={propertyAnalysisData.time_series.valuation_estimate}
                                    yxaxis_label={'Valuation Estimate ($)'}
                                    is_currency={true}
                                    percentChangeLastMonth={propertyAnalysisData.valuation_percent_change_last_month}
                                    percentChangeYearAgo={propertyAnalysisData.valuation_percent_change_year_ago}
                                    percentChange3YearsAgo={propertyAnalysisData.valuation_percent_change_3_years_ago}
                                    percentChangeLastTransacted={propertyAnalysisData.valuation_percent_change_last_transacted}
                                    estimateLastMonth={propertyAnalysisData.valuation_1_month}
                                    estimateYearAgo={propertyAnalysisData.valuation_1_year}
                                    estimate3YearsAgo={propertyAnalysisData.valuation_3_years}
                                    lastTransactedValue={propertyData.sold_price}
                                    lastTransactedDate={propertyData.sold_date}
                                    lastTransactedLabel="Last Sold"             
                                    showTimeSeries={true}
                                />
                                <PropertyMetric
                                    title="Days to Sell Estimate"
                                    metric_definition="The estimated number of days it would take to sell the property based on recent comparable sales data."
                                    lowerValue={propertyAnalysisData.days_to_sell_lower}
                                    midValue={propertyAnalysisData.days_to_sell}
                                    upperValue={propertyAnalysisData.days_to_sell_upper}
                                    midColor={agentDetails.agent_button_colour}
                                    yxaxis_label={'Days to Sell Estimate'}
                                    is_currency={false}
                                    showTimeSeries={false}
                                />
                                <PropertyMetric
                                    title="Rental Estimate"
                                    metric_definition="The estimated weekly rental income for the property based on recent comparable rental data."
                                    lowerValue={formatCurrency(propertyAnalysisData.rental_estimate_lower)}
                                    midValue={formatCurrency(propertyAnalysisData.rental_estimate)}
                                    upperValue={formatCurrency(propertyAnalysisData.rental_estimate_upper)}
                                    midColor={agentDetails.agent_button_colour}
                                    time_series_date={propertyAnalysisData.time_series.reporting_month}
                                    time_series_value={propertyAnalysisData.time_series.rental_estimate} 
                                    yxaxis_label={'Rental Estimate ($)'}
                                    is_currency={true}
                                    percentChangeLastMonth={propertyAnalysisData.rental_estimate_percent_change_last_month}
                                    percentChangeYearAgo={propertyAnalysisData.rental_estimate_percent_change_year_ago}
                                    percentChange3YearsAgo={propertyAnalysisData.rental_estimate_percent_change_3_years_ago}
                                    percentChangeLastTransacted={propertyAnalysisData.rental_estimate_percent_change_last_transacted}
                                    estimateLastMonth={propertyAnalysisData.rental_estimate_1_month}
                                    estimateYearAgo={propertyAnalysisData.rental_estimate_1_year}
                                    estimate3YearsAgo={propertyAnalysisData.rental_estimate_3_years}
                                    lastTransactedValue={propertyData.rented_price}
                                    lastTransactedDate={propertyData.rented_date}
                                    lastTransactedLabel="Last Rented"
                                    showTimeSeries={true}
                                />
                                
                                {/* <PropertyMetric
                                    title="Days to Lease"
                                    metric_definition="The estimated number of days it would take to lease the property based on recent comparable rental data."
                                    lowerValue={propertyAnalysisData.days_to_lease_lower}
                                    midValue={propertyAnalysisData.days_to_lease}
                                    upperValue={propertyAnalysisData.days_to_lease_upper}
                                    midColor={agentDetails.agent_button_colour}
                                    time_series_date={propertyAnalysisData.time_series.reporting_month}
                                    time_series_value={propertyAnalysisData.time_series.days_to_lease}
                                    yxaxis_label={'Days to Lease'}
                                    is_currency={false}   
                                    showTimeSeries={false}                      
                                /> */}
                        </div>
                    )
                )}
            </Tab>

            <Tab eventKey="comparables" title={renderTabTitle("Comparables")}>    
                { propertyData && agentDetails && (
                    <ComparableProperties
                        suburb={propertyData.suburb_id}
                        defaultPropertyType={propertyData.listing_property_type}
                        defaultBedrooms={propertyData.bedrooms}
                        agentDetails={agentDetails}
                        title="Comparable Properties"
                        subtitle={`Choose the most similar comparable properties to ${propertyData.address_text_short} to find your comparables estimate.`} 
                        modalContent={(
                            <ol>
                                <li>Find a list of comparable sold or rented properties using the filters provided. Try to find at least 5 if you can.</li>
                                <li>Exclude properties which are too different (this is only possible on list view at the moment).</li>
                                <li>The median price, max price, and min price of your final list is then calculated.</li>
                                <li>This would be an estimate of the price range for your property.</li>
                            </ol>
                        )}
                    />
                )}
            </Tab>
            <Tab eventKey="history" title={renderTabTitle("History")}>
                {propertyData && propertyData.history ? (
                    propertyData.history.length > 0 ? (
                    <>
                    <div style={{ marginBottom: '3rem', padding: '0.5rem', border: '0', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                        <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>Property History</h5>
                        <p>Historical sales and rental data for the property.</p>
                        <br />
                        <ul className="timeline" style={{ borderLeftColor: agentDetails.agent_colour }}>
                            {propertyData.history.map((event, index) => (
                            <li key={index} className="timeline-item mb-5" style={{ '--dot-color': agentDetails.agent_colour }}>
                                <span className="timeline-icon" style={{ backgroundColor: agentDetails.agent_colour }}>
                                    
                                </span>
                                <span className="badge" style={{ marginBottom: '10px', backgroundColor: agentDetails.agent_colour, color: agentDetails.agent_button_colour, borderRadius: '50px', padding: '5px 10px'}}>
                                    {event.type}
                                </span>                                                        
                                <h5 className="fw-bold">
                                {event.price !== null && event.price !== 0 ? (<span> ${event.price.toLocaleString()}</span>): null}
                                {event.price !== null && event.price !== 0 ? (<span style={{ fontSize: '0.8rem', color: '#7e8594' }}> {event.label}</span>): (<span style={{ fontSize: '0.8rem', color: '#7e8594' }}> PRICE UNKNOWN </span>)}
                                </h5>
                                <p className="text-muted mb-2 fw-bold">
                                    {event.date !== null ? new Date(event.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Unknown Date'}
                                </p>
                                <p className="text-muted"></p>
                            </li>
                            ))}
                        </ul>
                    </div>
                    </>
                    ) : (
                        <p className="text-muted">No history available.</p>
                    )
                ) : null}
            </Tab>
        </Tabs>
            </Container>
            <ToastContainer
            style={{
                position: 'fixed',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 9998,
                opacity: 1
            }}
            className={`p-3 ${isMobile ? 'toast-container-mobile' : 'toast-container-desktop'}`}>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={5000} autohide style={{opacity: 1}}  bg='light'>
                <Toast.Body style={{ textAlign: 'center' }}>
                Click <b>Analyse Property</b> after editing the property features.
                </Toast.Body>
            </Toast>
            </ToastContainer>
        </div>
        
    )
};

export default PropertyProfile;