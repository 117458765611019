import React, { useEffect, useState } from 'react';
import { APIProvider, Map, AdvancedMarker, InfoWindow, useAdvancedMarkerRef, Pin } from '@vis.gl/react-google-maps';
import { FaCheckCircle, FaTimesCircle, FaStar } from 'react-icons/fa';

const GOOGLE_MAPS_API_KEY = "AIzaSyA5B0v3lB76103qW9GDOAF-nbOcSyxm_5Q";

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

const MarkerWithInfoWindow = ({ position, property, pinColour, isActive, onClick, onClose }) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const propertyTitleStyle = {
        fontWeight: 'bold',
        fontSize: '14px',
        margin: '0px',
        padding: '0px',
        marginBottom: '10px',
    };

    const pinStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: pinColour,
        color: '#000',
        borderColor: '#000',
        borderRadius: '10px',
        padding: '5px',
        textAlign: 'center',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    };

    const infoWindowStyle = {
        display: 'flex',
        fontFamily: 'Arial, sans-serif',
        fontSize: '12px',
        lineHeight: '1.6',
        padding: '0px',
        marginLeft: '5px',
        margin: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    };

    const infoDetailsStyle = {
        marginTop: '10px',
    };

    const infoRowStyle = {
        margin: '0',
    };

    return (
        <>
            <AdvancedMarker ref={markerRef} position={position} onClick={onClick}>
                <div style={pinStyle}>{property.display_price}</div>
            </AdvancedMarker>

            {isActive && (
                <InfoWindow anchor={marker} onClose={onClose} headerContent={<p style={propertyTitleStyle}>{property.display_address}</p>}>
                    <div style={infoWindowStyle}>
                        <img src={property.photo_url_main} style={{ width: "100%", maxWidth: '300px' }} alt="Property" />
                        <div style={infoDetailsStyle}>
                            <p style={infoRowStyle}>Status: {property.listing_status}</p>
                            <p style={infoRowStyle}>Type: {property.property_type_display}</p>
                            <p style={infoRowStyle}>Price: {property.display_price}</p>
                            <p style={infoRowStyle}>Bedrooms: {property.bedrooms}</p>
                            <p style={infoRowStyle}>Days on Market: {property.days_on_market}</p>
                            <p style={infoRowStyle}>{property.filter_date_label}: {formatDate(property.filter_date)}</p>
                        </div>
                    </div>
                </InfoWindow>
            )}
        </>
    );
};

const ListingMap = ({ listingData, agentDetails }) => {
    const [markers, setMarkers] = useState([]);
    const [center, setCenter] = useState(null);
    const [zoom, setZoom] = useState(null);

    const handleMarkerClick = (newIndex) => {
        setMarkers(listingData.map((property, index) => (
            <MarkerWithInfoWindow
                key={index}
                position={{ lat: property.latitude, lng: property.longitude }}
                property={property}
                pinColour={agentDetails.agent_colour}
                isActive={newIndex === index}
                onClick={() => handleMarkerClick(index)}
                onClose={() => handleMarkerClick(-1)}
            />
        )));
    };

    useEffect(() => {
        if (listingData && agentDetails && Array.isArray(listingData) && listingData.length > 0) {
            console.log("Updating map center and zoom with listingData:", listingData);
            
            const totalCoords = listingData.reduce((acc, property) => {
                acc.lat += property.latitude;
                acc.lng += property.longitude;
                return acc;
            }, { lat: 0, lng: 0 });
    
            const averageLat = totalCoords.lat / listingData.length;
            const averageLng = totalCoords.lng / listingData.length;
            
            setCenter({ lat: averageLat, lng: averageLng });
    
            setMarkers(listingData.map((property, index) => (
                <MarkerWithInfoWindow
                    key={index}
                    position={{ lat: property.latitude, lng: property.longitude }}
                    property={property}
                    pinColour={agentDetails.agent_colour}
                    isActive={false}
                    onClick={() => handleMarkerClick(index)}
                    onClose={() => handleMarkerClick(-1)}
                />
            )));
    
            const zoomLevel = 16;
            setZoom(zoomLevel);
            
        } else {
            setMarkers([]);
        }
    }, [listingData, agentDetails]);

    return (
        <div>
            {
                listingData === null || center === null || zoom === null ? (
                    <div style={{ height: '600px' }}>
                        <p style={{ textAlign: 'center' }}>No comparables found.</p>
                    </div>
                ) : (
                    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
                        <Map
                            mapId='1ae451cd191c1a06'
                            defaultCenter={center}
                            defaultZoom={zoom}
                            style={{ width: '100%', height: '600px' }}
                            onIdle={(e) => {
                                const map = e.map;
                                setCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
                                setZoom(map.getZoom());
                            }}
                        
                        >
                            {markers}
                        </Map>
                    </APIProvider>
                )
            }
        </div>
    );
};

export default ListingMap;