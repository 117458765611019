import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { FaSave } from 'react-icons/fa'; // Import save icon
import './Styles.css';
import { handleTokenAndPostRequest } from '../utils/utils';

function Notifications() {
  const [agentDetails, setAgentDetails] = useState(null);
  const [isSuburbMarketUpdatesChecked, setSuburbMarketUpdatesChecked] = useState(true); // Default to true
  const [isPropertyUpdatesChecked, setPropertyUpdatesChecked] = useState(true); // Default to true
  const [noTokenMessage, setNoTokenMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState(null);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [agentLoading, setAgentLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const param_token = params.get('token');
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';

  useEffect(() => {
    const authenticateToken = async () => {
      const token = localStorage.getItem('jwt_token') || param_token;
      if (!token) {
        setNoTokenMessage("No valid token found.");
        navigate('/'); // Redirect to home page
        return;
      }

      try {
        const response = await handleTokenAndPostRequest(
          `${window.api_host}/authenticate-token`,
          {},
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        );

        if (response.data.status === 'error') {
          setNoTokenMessage(response.data.message);
          navigate('/'); // Redirect to home page
        } else {
          setEmail(response.data.email);
        }
      } catch (error) {
        console.error('Error authenticating token:', error);
        setNoTokenMessage('Failed to authenticate token.');
        navigate('/'); // Redirect to home page
      }
    };

    authenticateToken();
  }, [param_token, navigate]);

  useEffect(() => {
    const fetchUserSettings = async () => {
      if (!email) return;
      try {
        const response = await handleTokenAndPostRequest(
          `${window.api_host}/read-user-settings`,
          {},
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwt_token') || param_token}`,
          }
        );
        const data = response.data;
        setSuburbMarketUpdatesChecked(data.suburb_market_updates ?? true);
        setPropertyUpdatesChecked(data.property_updates ?? true);
      } catch (error) {
        console.error('Error fetching user settings:', error);
      } finally {
        setSettingsLoading(false);
      }
    };

    fetchUserSettings();
  }, [email, param_token]);

  useEffect(() => {
    const fetchAgentDetails = async () => {
      const url = `${window.api_host}/agency`;

      try {
        const response = await handleTokenAndPostRequest(
          url,
          {
            agent_slug: agent_slug,
          },
          {
            'Content-Type': 'application/json',
          }
        );

        const data = response.data;
        setAgentDetails(data);
        document.title = `${data.agency_name_short} Notifications`;
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setAgentLoading(false);
      }
    };

    fetchAgentDetails();
  }, [agent_slug]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) {
      setNoTokenMessage("Sorry, we can't save your settings as there is no email attached to this request");
      return;
    }
    setIsSaving(true);
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/save-user-settings`,
        {
          user_settings: {
            suburb_market_updates: isSuburbMarketUpdatesChecked,
            property_updates: isPropertyUpdatesChecked,
          },
        },
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt_token') || param_token}`,
        }
      );

      if (response.data.status === 'success') {
        setSuccessMessage("Your notification settings have been successfully saved.");
        setNoTokenMessage('');
      } else {
        setNoTokenMessage('There was an error processing your request.');
      }
    } catch (error) {
      console.error('Error:', error);
      setNoTokenMessage('Failed to connect. Please try again later.');
    } finally {
      setIsSaving(false);
    }
  };

  if (settingsLoading || agentLoading) {
    return (
      <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Container className="agent-page" style={{ maxWidth: '600px', marginTop: '130px' }}>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>Notification Settings</h1>
          <p>Please select the notifications you wish to receive.</p>
        </Col>
      </Row>
      <Form className="w-100" onSubmit={handleSubmit}>
        <Row className="mb-2">
          <Col className="d-flex">
            <Form.Check 
              className="align-self-start custom-switch"
              type="switch" 
              label="Suburb Market Updates" 
              checked={isSuburbMarketUpdatesChecked}
              onChange={e => setSuburbMarketUpdatesChecked(e.target.checked)}
              style={{ '--agent-colour': agentDetails.agent_colour }}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex">
            <Form.Check 
              className="align-self-start custom-switch"
              type="switch" 
              label="Property Updates" 
              checked={isPropertyUpdatesChecked}
              onChange={e => setPropertyUpdatesChecked(e.target.checked)}
              style={{ '--agent-colour': agentDetails.agent_colour }}
            />
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="w-100" disabled={isSaving} style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }}>
          {isSaving ? <Spinner animation="border" size="sm" /> : <><FaSave /> Save Settings</>}
        </Button>
        {successMessage && <Alert variant="secondary" className="mt-3">{successMessage}</Alert>}
        {noTokenMessage && <Alert variant="secondary" className="mt-3">{noTokenMessage}</Alert>}
      </Form>
    </Container>
  );
}

export default Notifications;