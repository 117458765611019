import React from 'react';
import PropTypes from 'prop-types';

const ValueDisplayGroup = ({ lowerValue, midValue, upperValue, midColor, listingType, showTitle }) => {
    const marginBottomP = '0.25rem';

    return (
        <div>
            {showTitle && (
                <h6 style={{ fontSize: '1rem', fontWeight: '600', textAlign: 'center', marginBottom: '0.6rem' }}>
                    {listingType === 'Rent' ? 'Rented Price Range' : 'Sold Price Range'}
                </h6>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', textAlign: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ marginBottom: marginBottomP, fontSize: '0.875rem', color: '#7e8594' }}>LOW</p>
                    <p style={{ marginBottom: marginBottomP, fontSize: '1.5rem' }}>{lowerValue}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ marginBottom: marginBottomP, fontSize: '0.875rem', color: '#7e8594' }}>MID</p>
                    <p style={{ marginBottom: marginBottomP, fontSize: '1.875rem', fontWeight: 'bold', color: midColor }}>{midValue}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ marginBottom: marginBottomP, fontSize: '0.875rem', color: '#7e8594' }}>HIGH</p>
                    <p style={{ marginBottom: marginBottomP, fontSize: '1.5rem' }}>{upperValue}</p>
                </div>
            </div>
        </div>
    );
};

export default ValueDisplayGroup;