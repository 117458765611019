import axios from 'axios';

export const handleTokenAndPostRequest = async (url, data, headers = {}) => {
  try {
    // Get 'jwt_token' from local storage
    let jwt_token = localStorage.getItem('jwt_token');

    // Get 'token' from URL query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const param_token = urlParams.get('token');

    // If 'token' exists in URL, set it in local storage
    if (param_token) {
      localStorage.setItem('param_token', param_token);
    }

    // Get 'param_token' from local storage
    const storedParamToken = localStorage.getItem('param_token');

    // Check if data is FormData
    if (data instanceof FormData) {
      // Append additional fields to FormData
      data.append('param_token', storedParamToken);
      data.append('source', window.location.href);
    } else {
      // Handle non-FormData cases
      data = {
        ...data,
        param_token: storedParamToken,
        source: window.location.href,
      };
    }

    // Add 'Authorization' header
    const requestHeaders = {
      ...headers,
      'Authorization': `Bearer ${jwt_token}`,
    };

    console.log('Request Headers:', requestHeaders);
    // Make the POST request
    const response = await axios.post(url, data, { headers: requestHeaders });

    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const formatCurrency = (value) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  const roundedValue = Math.round(value);
  if (roundedValue >= 1000000) {
    return `$${(roundedValue / 1000000).toFixed(2)}m`; // Format as millions
  } else if (roundedValue >= 10000) {
    return `$${(roundedValue / 1000).toFixed(0)}k`; // Format as thousands
  } else {
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'USD',
      minimumFractionDigits: 0,  // Ensure no decimal places are displayed
      maximumFractionDigits: 0 
    }).format(roundedValue); // Format as hundreds
  }
};



export const setMetaTags = ({ title, description, keywords, url, image }) => {
  document.title = title;

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', description);
  } else {
    const meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = description;
    document.head.appendChild(meta);
  }

  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', keywords);
  } else {
    const meta = document.createElement('meta');
    meta.name = 'keywords';
    meta.content = keywords;
    document.head.appendChild(meta);
  }

  const metaOgTitle = document.querySelector('meta[property="og:title"]');
  if (metaOgTitle) {
    metaOgTitle.setAttribute('content', title);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('property', 'og:title');
    meta.content = title;
    document.head.appendChild(meta);
  }

  const metaOgDescription = document.querySelector('meta[property="og:description"]');
  if (metaOgDescription) {
    metaOgDescription.setAttribute('content', description);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('property', 'og:description');
    meta.content = description;
    document.head.appendChild(meta);
  }

  const metaOgUrl = document.querySelector('meta[property="og:url"]');
  if (metaOgUrl) {
    metaOgUrl.setAttribute('content', url);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('property', 'og:url');
    meta.content = url;
    document.head.appendChild(meta);
  }

  const metaOgImage = document.querySelector('meta[property="og:image"]');
  if (metaOgImage) {
    metaOgImage.setAttribute('content', image);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('property', 'og:image');
    meta.content = image;
    document.head.appendChild(meta);
  }

  const metaTwitterTitle = document.querySelector('meta[name="twitter:title"]');
  if (metaTwitterTitle) {
    metaTwitterTitle.setAttribute('content', title);
  } else {
    const meta = document.createElement('meta');
    meta.name = 'twitter:title';
    meta.content = title;
    document.head.appendChild(meta);
  }

  const metaTwitterDescription = document.querySelector('meta[name="twitter:description"]');
  if (metaTwitterDescription) {
    metaTwitterDescription.setAttribute('content', description);
  } else {
    const meta = document.createElement('meta');
    meta.name = 'twitter:description';
    meta.content = description;
    document.head.appendChild(meta);
  }

  const metaTwitterImage = document.querySelector('meta[name="twitter:image"]');
  if (metaTwitterImage) {
    metaTwitterImage.setAttribute('content', image);
  } else {
    const meta = document.createElement('meta');
    meta.name = 'twitter:image';
    meta.content = image;
    document.head.appendChild(meta);
  }
};