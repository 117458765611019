import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';

function ShareModal(props) {
  const handleCopyLink = () => {
    const link = props.shareUrl; // Use the URL passed in as a prop
    navigator.clipboard.writeText(link).then(() => {
      props.onCopySuccess();
      setTimeout(() => {
        props.onHide();
      }, 10); // Close the modal after 0.7 seconds
    }, () => {
      // Handle copy failure if needed
    });
  };

  return (
    <Modal centered style={{ zIndex: 9999 }} show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Share Valuation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You can copy the link below and send it via SMS or email to the person you want to share it with.</p>
        <Button
          variant="primary"
          onClick={handleCopyLink}
          disabled={props.isSavingValuation || props.analysisLoading}
          style={{
            width: '100%',
            backgroundColor: props.agentDetails.agent_colour,
            borderColor: props.agentDetails.agent_colour,
            color: props.agentDetails.agent_button_colour
          }}
        >
          {(props.isSavingValuation || props.analysisLoading) ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '8px' }}
              />
              Saving Valuation
            </>
          ) : (
            <>
              <FaCopy style={{ marginRight: '8px' }} />
              Copy Link
            </>
          )}
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default ShareModal;