import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image, Spinner, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import MainNav from '../components/MainNav';
import { debounce } from 'lodash';
import axios from 'axios';
import './Styles.css';

function AgentHome() {
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';
  const [agentDetails, setAgentDetails] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  useEffect(() => {
    if (agentDetails) {
      document.title = `${agentDetails.agency_name_short} Home`;
      document.body.style.backgroundImage = `url(${agentDetails.landing_background_url})`;
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundPosition = 'center center';
      document.body.style.backgroundAttachment = 'fixed';
      document.body.style.backgroundSize = 'cover';
    }

    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [agentDetails]);
  
  useEffect(() => {
    const url = `${window.api_host}/agency`;  // Adjusted to remove specific slug from URL path
    
    console.log(agent_slug)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            agent_slug: agent_slug,  // Include agent_slug in the body
            token: token,
            source: window.location.href  // Adding the current page URL as 'source'
        })
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => setAgentDetails(data))
      .catch(error => console.error('Error:', error));
    }, [agent_slug, token]);

  if (!agentDetails) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Spinner animation="border" />
        </div>
    );
  }

  return (

<div>
  <Container style={{ marginTop: '120px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex' }}>
    <Row className="justify-content-md-center" style={{ maxWidth: '600px' }}>
      <Col md="auto">
        <h1>Sell with {agentDetails.agency_name_short}</h1>
        <p>Welcome to our property insights website. We provide you with the latest property market insights and reports for your suburb or property detailing trends in property prices, transaction volumes and time on market as well as other metrics.</p>
      </Col>
    </Row>
    <Row className="justify-content-center" style={{ marginTop: '20px', gap: '10px' }}>
      <Col md="auto">
        <Button as={Link} to={`/suburb-search/${agentDetails.landing_page_slug}`} variant="primary" className="submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }}>
          Suburb Report
        </Button>
      </Col>
      <Col md="auto">
        <Button as={Link} to={`/property-search/${agentDetails.landing_page_slug}`} variant="primary" className="submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }}>
          Property Report
        </Button>
      </Col>
    </Row>
  </Container>
</div>
  );
}

export default AgentHome;