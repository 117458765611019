import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import MainNav from '../components/MainNav';
import { debounce } from 'lodash';
import axios from 'axios';
import './Styles.css';
import { handleTokenAndPostRequest } from '../utils/utils';

function SuburbSearch() {
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';
  const [agentDetails, setAgentDetails] = useState(() => {
    const storedAgentDetails = sessionStorage.getItem('agentDetails');
    return storedAgentDetails ? JSON.parse(storedAgentDetails) : null;
  });
  const [selectedSuburb, setSelectedSuburb] = useState(null);
  const [message, setMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectKey, setSelectKey] = useState(0); 
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(true);  
  const token = params.get('token');

  useEffect(() => {
    if (agentDetails) {
      document.title = `${agentDetails.agency_name_short} Suburb Report Search`;
      document.body.style.backgroundImage = `url(${agentDetails.landing_background_url})`;
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundPosition = 'center center';
      document.body.style.backgroundAttachment = 'fixed';
      document.body.style.backgroundSize = 'cover';
    }

    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [agentDetails]);
    
  useEffect(() => {
    const storedSlug = sessionStorage.getItem('landing_page_slug');
    if (!agentDetails || storedSlug !== agent_slug) {
      setIsLoading(true);
      const fetchAgentDetails = async () => {
        const url = `${window.api_host}/agency`;

        try {
          const response = await handleTokenAndPostRequest(
            url,
            {
              agent_slug: agent_slug,
            },
            {
              'Content-Type': 'application/json',
            }
          );

          const data = response.data;
          setAgentDetails(data);
          sessionStorage.setItem('agentDetails', JSON.stringify(data));
          sessionStorage.setItem('landing_page_slug', agent_slug);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchAgentDetails();
    } else {
      setIsLoading(false);
    }
  }, [agent_slug, agentDetails]);


  const loadSuburbs = debounce((inputValue, callback) => {
    axios.get(`${window.api_host}/suburb-search?q=${inputValue}`)
      .then(response => {
        const options = response.data.map(suburb => ({
          label: suburb.suburb_postcode,
          value: suburb.suburb_id
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error loading property options:', error);
        callback([]);
      });
  }, 500);

  const handleSuburbChange = (selectedOption) => {
    setSelectedSuburb(selectedOption);
    if (selectedOption) {
      const suburb = selectedOption.value;
      const agentSlug = agentDetails.landing_page_slug;
      navigate(`/suburb-profile/${suburb}/${agentSlug}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedSuburb) {
        setMessage('Please select a suburb to view.');
        return;
    }

    const suburb = selectedSuburb.value;
    const agentSlug = agentDetails.landing_page_slug;

    navigate(`/suburb-profile/${suburb}/${agentSlug}`);
  };

  if (!agentDetails) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Spinner animation="border" />
        </div>
    );
  }

  return (
    <div>
    <Container className="agent-page" style={{ maxWidth: '600px', marginTop: '120px', justifyContent: 'center', alignItems: 'center'}}>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>View your suburb report</h1>
          <p>Search for your suburb by name or postcode and view our property trend suburb report with prices, time on market and other sold data.</p>
          </Col>
      </Row>      
      <Form className="w-100" onSubmit={handleSubmit}>
        <Row className="mb-2">
            <Col>
                <AsyncSelect
                    key={selectKey}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadSuburbs}
                    onChange={handleSuburbChange}
                    value={selectedSuburb}
                    noOptionsMessage={() => null}      
                    placeholder="Suburb name or postcode"
                    openMenuOnClick={false}
                    styles={{
                        control: (provided) => ({ 
                            ...provided, 
                            borderRadius: '0.375rem', 
                            textAlign: 'left', 
                        }),
                        placeholder: (provided) => ({ 
                            ...provided, 
                            textAlign: 'left',
                        }),
                        menu: (provided) => ({ 
                            ...provided, 
                            textAlign: 'left',
                        }),
                        singleValue: (provided) => ({
                            ...provided, 
                            textAlign: 'left',
                        }),
                    }}
                />
            </Col>
        </Row>
        <Button variant="primary" type="submit" className="w-100 submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }} disabled={isUpdating}>        
            {isUpdating ? <Spinner animation="border" size="sm" /> : 'Find my suburb report'}
        </Button>
        {message && <Alert variant="secondary" className="mt-3">{message}</Alert>}
        </Form>
    </Container>
    </div>
  );
}

export default SuburbSearch;