import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Spinner, Image } from 'react-bootstrap';
import './Styles.css';

import { handleTokenAndPostRequest } from '../utils/utils';

function Unsubscribe() {
  const [agentDetails, setAgentDetails] = useState(null);
  const [isSuburbMarketUpdatesChecked, setSuburbMarketUpdatesChecked] = useState(false);
  const [isPropertyUpdatesChecked, setPropertyUpdatesChecked] = useState(false);
  const [noTokenMessage, setNoTokenMessage] = useState('');
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const param_token = params.get('token');
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';


  useEffect(() => {
    const fetchAgentDetails = async () => {
      const url = `${window.api_host}/agency`;
  
      try {
        const response = await handleTokenAndPostRequest(
          url,
          {
            agent_slug: agent_slug,
          },
          {
            'Content-Type': 'application/json',
          }
        );
  
        const data = response.data;
        setAgentDetails(data);
  
        // Set background image
        document.body.style.backgroundImage = `url(${data.landing_background_url})`;
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundPosition = 'center center';
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.backgroundSize = 'cover';
        document.title = `${data.agency_name_short} Unsubscribe`;
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchAgentDetails();
  }, [agent_slug, param_token]);


  useEffect(() => {
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!param_token) {
      setNoTokenMessage("Sorry, we can't unsubscribe you as there is no email attached to this request");
      return;
    }
    setIsUnsubscribing(true);
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/unsubscribe`,
        {
          suburb_market_updates: isSuburbMarketUpdatesChecked,
          property_updates: isPropertyUpdatesChecked,
          agency_id: agentDetails.agency_id,
          agent_id: agentDetails.agent_id,
          agent_slug: agent_slug,
        },
        {
          'Content-Type': 'application/json',
        }
      );

      setIsUnsubscribing(false);
      if (response.data.success) {
        setSuccessMessage("You have been successfully unsubscribed.");
        setNoTokenMessage('');
      } else {
        setNoTokenMessage('There was an error processing your request.');
      }
    } catch (error) {
      console.error('Error:', error);
      setNoTokenMessage('Failed to connect. Please try again later.');
      setIsUnsubscribing(false);
    }
  };

  if (!agentDetails) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Spinner animation="border" />
        </div>
    );
  }
  
  return (
    <Container className="agent-page" style={{ maxWidth: '600px' }}>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>Unsubscribe from Updates</h1>
          <p>Please select the updates you wish to unsubscribe from.</p>
        </Col>
      </Row>
      <Form className="w-100" onSubmit={handleSubmit}>
        <Row className="mb-2">
          <Col className="d-flex">
            <Form.Check 
              className="align-self-start"
              type="checkbox" 
              label="Suburb Market Updates" 
              checked={isSuburbMarketUpdatesChecked}
              onChange={e => setSuburbMarketUpdatesChecked(e.target.checked)}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex">
            <Form.Check 
              className="align-self-start"
              type="checkbox" 
              label="Property Updates" 
              checked={isPropertyUpdatesChecked}
              onChange={e => setPropertyUpdatesChecked(e.target.checked)}
            />
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="w-100" disabled={isUnsubscribing} style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }}>
          {isUnsubscribing ? <Spinner animation="border" size="sm" /> : 'Unsubscribe'}
        </Button>
        {successMessage && <Alert variant="secondary" className="mt-3">{successMessage}</Alert>}
        {noTokenMessage && <Alert variant="secondary" className="mt-3">{noTokenMessage}</Alert>}
      </Form>
    </Container>
  );
}

export default Unsubscribe;