import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Spinner } from 'react-bootstrap';
import EnquiryPage from './pages/EnquiryPage';
// import SuburbPreferences from './pages/SuburbPreferences';
// import PropertyPreferences from './pages/PropertyPreferences';
import PropertySearch from './pages/PropertySearch';
import PropertySearchLive from './pages/PropertySearchLive';
import AgentHome from './pages/AgentHome';
import SuburbSearch from './pages/SuburbSearch';
import SuburbProfile from './pages/SuburbProfile';
import PropertyProfile from './pages/PropertyProfile';
import Unsubscribe from './pages/Unsubscribe';
import Notifications from './pages/Notifications';
import MyProperties from './pages/MyProperties';
import MainNav from './components/MainNav';
import { handleTokenAndPostRequest } from './utils/utils'; // Adjust the import path as needed

// Initialize GA4
ReactGA.initialize('G-W7Y0RCBV7Q'); // Replace with your GA4 Measurement ID

if (window.location.hostname === 'localhost' || window.location.hostname.includes('ngrok')) {
  window.api_host = 'https://property-app-dev--production-api-dev.modal.run';
} else {
  window.api_host = 'https://property-app--production-api.modal.run';
}
const TrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;
    // console.log(`Logging pageview for ${page_path}`);
    ReactGA.send({ hitType: 'pageview', page: page_path });
  }, [location]);

  return null;
};

const useAgentSlug = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const lastSegment = pathSegments[pathSegments.length - 1];
  const validSlugs = ['spica-real-estate', 'stylux-properties'];

  return validSlugs.includes(lastSegment) ? lastSegment : 'property-insights';
};

const AppContent = () => {
  const [agentDetails, setAgentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const agent_slug = useAgentSlug();

  useEffect(() => {
    const fetchAgentDetails = async () => {
      setLoading(true);
      setError(null);

      const landingPageSlug = sessionStorage.getItem('landing_page_slug');
      const storedAgentDetails = sessionStorage.getItem('agentDetails');

      if (landingPageSlug === agent_slug && storedAgentDetails) {
        setAgentDetails(JSON.parse(storedAgentDetails));
        setLoading(false);
        return;
      }

      const url = `${window.api_host}/agency`;  // Adjusted to remove specific slug from URL path

      try {
        const response = await handleTokenAndPostRequest(
          url,
          {
            agent_slug: agent_slug,  // Include agent_slug in the body
          },
          {
            'Content-Type': 'application/json',
          }
        );

        setAgentDetails(response.data);
        sessionStorage.setItem('landing_page_slug', agent_slug);
        sessionStorage.setItem('agentDetails', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error:', error);
        setError('Failed to load agent details.');
      } finally {
        setLoading(false);
      }
    };

    fetchAgentDetails();
  }, [agent_slug]);

  if (loading) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
        }}>
        <Spinner animation="border" />
        </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <MainNav agentDetails={agentDetails} />
      <TrackPageViews />
      <Routes>
        <Route path="/:agent_slug" element={<AgentHome />} />
        <Route path="/" element={<AgentHome />} />
        <Route path="/enquiry/:agent_slug" element={<EnquiryPage />} />
        <Route path="/enquiry/" element={<EnquiryPage />} />
        {/* <Route path="/suburb-preferences/:agent_slug" element={<SuburbPreferences />} /> */}
        {/* <Route path="/property-preferences/:agent_slug" element={<PropertyPreferences />} /> */}
        <Route path="/property-search/:agent_slug" element={<PropertySearch />} />
        <Route path="/property-search" element={<PropertySearch />} />
        <Route path="/property-search-live/:agent_slug" element={<PropertySearchLive />} />
        <Route path="/property-search-live" element={<PropertySearchLive />} />
        <Route path="/suburb-search/:agent_slug" element={<SuburbSearch />} />
        <Route path="/suburb-search/" element={<SuburbSearch />} />
        <Route path="/suburb-profile/:suburb/:agent_slug" element={<SuburbProfile />} />
        <Route path="/suburb-profile/:suburb/" element={<SuburbProfile />} />
        <Route path="/property-profile/:property/:agent_slug" element={<PropertyProfile />} />
        <Route path="/property-profile/:property/" element={<PropertyProfile />} />
        <Route path="/unsubscribe/:agent_slug" element={<Unsubscribe />} />
        <Route path="/unsubscribe/" element={<Unsubscribe />} />
        <Route path="/notifications/:agent_slug" element={<Notifications />} />
        <Route path="/notifications/" element={<Notifications />} />
        <Route path="/my-properties/:agent_slug" element={<MyProperties />} />
        <Route path="/my-properties/" element={<MyProperties />} />
      </Routes>
    </>
  );
};

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  return (
    <Router>
      <div style={{ marginTop: isMobile ? '56px':  '80px'}}>
        <AppContent />
      </div>
    </Router>
  );
}

export default App;