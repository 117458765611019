import React, { useEffect, useState } from 'react';
import { Card, Carousel, Container, Row, Col, Spinner, Tabs, Tab, Button, Alert, Form } from 'react-bootstrap';
import { FaBed, FaBath, FaCar, FaBookmark, FaRegBookmark, FaCalculator, FaEdit, FaTape, FaHome, FaBuilding } from 'react-icons/fa';
import MainNav from '../components/MainNav'; // Adjust the import path as needed
import SignInModal from '../components/SignInModal'; // Adjust the import path as needed
import ValueDisplayGroup from '../components/ValueDisplayGroup';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { handleTokenAndPostRequest, formatCurrency } from '../utils/utils';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import axios from 'axios';

function MyProperties() {
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';
  const [properties, setProperties] = useState([]);
  const [valuations, setValuations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [agentDetails, setAgentDetails] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [tabKey, setTabKey] = useState('my-valuations');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  const [isSavingValuation, setIsSavingValuation] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const param_token = params.get('token');
  const navigate = useNavigate();

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [message, setMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectKey, setSelectKey] = useState(0); 
  const [isLoading, setIsLoading] = useState(true);

  const renderTabTitle = (title) => (
    <span style={{ display: 'flex', marginRight: '10px', marginLeft: '10px', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
        {title}
    </span>
  );

  const fetchProperties = async () => {
    const url = `${window.api_host}/my-properties`;
    try {
      const response = await handleTokenAndPostRequest(
        url,
        { agent_slug: agent_slug },
        { 'Content-Type': 'application/json' }
      );
      setProperties(response.data);
    } catch (error) {
      setError('Failed to fetch properties');
      setProperties([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchValuations = async () => {
    const url = `${window.api_host}/my-valuations`;
    try {
      const response = await handleTokenAndPostRequest(
        url,
        { agent_slug: agent_slug },
        { 'Content-Type': 'application/json' }
      );
      setValuations(response.data);
    } catch (error) {
      setError('Failed to fetch valuations');
      setValuations([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties();
    fetchValuations();
  }, [agent_slug]);

  useEffect(() => {
    const fetchAgentDetails = async () => {
      const url = `${window.api_host}/agency`;
      try {
        const response = await handleTokenAndPostRequest(
          url,
          { agent_slug: agent_slug },
          { 'Content-Type': 'application/json' }
        );
        setAgentDetails(response.data);
      } catch (error) {
        console.error('Error fetching agent details:', error);
      }
    };
    fetchAgentDetails();
  }, [agent_slug]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (agentDetails) {
      document.title = `${agentDetails.agency_name_short} Property Report`;
      document.documentElement.style.setProperty('--nav-link-active-border-color', agentDetails.agent_colour);
      document.documentElement.style.setProperty('--nav-link-active-color', agentDetails.agent_button_colour);            
      document.documentElement.style.setProperty('--nav-link-font-size', isMobile ? '0.8rem' : '1rem');            
    }
  }, [agentDetails]);

  if (loading) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  const handleCardClick = (full_slug, valuation_id) => {
    const url = valuation_id 
      ? `/property-profile/${full_slug}/${agent_slug}?valuation_id=${valuation_id}` 
      : `/property-profile/${full_slug}/${agent_slug}`;
    navigate(url);
  };

  const handleAddPropertyClick = () => {
    navigate(`/property-search-live/${agent_slug}`);
  };

  const handleModalSuccess = () => {
    setModalShow(false);
    fetchProperties(); // Reload properties after successful login
  };


  const loadProperties = debounce((inputValue, callback) => {
    axios.get(`${window.api_host}/property-search?q=${inputValue}`)
      .then(response => {
        const options = response.data.map(property => ({
          label: property.address_text,
          value: property.id,
          slug: property.slug
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error loading property options:', error);
        callback([]);
      });
  }, 500);

  const handlePropertyChange = (selectedOption) => {
    setSelectedProperty(selectedOption);
    if (selectedOption) {
      const property_id = selectedOption.value;
      const slug = selectedOption.slug;
      const agentSlug = agentDetails.landing_page_slug;
      navigate(`/property-profile/${slug}-${property_id}/${agentSlug}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedProperty) {
      setMessage('Please select a property to view the report.');
      return;
    }
    // redirect the user to the Property Page
    const property_id = selectedProperty.value;
    const slug = selectedProperty.slug;
    const agentSlug = agentDetails.landing_page_slug;
    
    navigate(`/property-profile/${slug}-${property_id}/${agentSlug}`);
  };  

  const fetchLiveValuation = async (property) => {
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/property-analysis`,
        {
          property_id: property.id,
          bedrooms: property.bedrooms === '' || property.bedrooms === null ? 0 : property.bedrooms,
          bathrooms: property.bathrooms === '' || property.bathrooms === null ? 0 : property.bathrooms,
          carspaces: property.carspaces === '' || property.carspaces === null ? 0 : property.carspaces,
          internal_area: property.internal_area,
          land_area: property.land_area === '' || property.land_area === null ? 0 : property.land_area,
          property_type: property.property_type,
          features: property.features,
          property_notes: property.property_notes,
          last_sold_date: property.sold_date,
          last_sold_price: property.sold_price,
          last_rented_date: property.rented_date,
          last_rented_price: property.rented_price,
          state: property.state,
          postcode: property.postcode,
          latitude: property.latitude,
          longitude: property.longitude,
          page: 'MyProperties',
          agent_slug: agent_slug,
        },
        {
          'Content-Type': 'application/json',
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching live valuation:', error);
      return null;
    }
  };

  const renderProperties = (propertiesList, type) => (
    propertiesList.length === 0 ? (
      <div className="text-center">
        {localStorage.getItem('jwt_token') ? (
          <div style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div>
              <p>No properties found. Start adding properties to your list.</p>
            </div>
            <div style={{ width: '100%', maxWidth: '500px'}}>
            <Form className="w-100" onSubmit={handleSubmit}>
            <Row className="mb-2">
                <Col>
                    <AsyncSelect
                        key={selectKey}
                        cacheOptions
                        defaultOptions
                        loadOptions={loadProperties}
                        onChange={handlePropertyChange}
                        value={selectedProperty}
                        noOptionsMessage={() => null}      
                        placeholder="Search property address"
                        openMenuOnClick={false}
                        styles={{
                            control: (provided) => ({ 
                                ...provided, 
                                borderRadius: '0.375rem', 
                                textAlign: 'left', 
                            }),
                            placeholder: (provided) => ({ 
                                ...provided, 
                                textAlign: 'left',
                            }),
                            menu: (provided) => ({ 
                                ...provided, 
                                textAlign: 'left',
                            }),
                            singleValue: (provided) => ({
                                ...provided, 
                                textAlign: 'left',
                            }),
                        }}
                    />
                </Col>
            </Row>
            <Button variant="primary" type="submit" className="w-100 submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }} disabled={isUpdating}>
              {isUpdating ? <Spinner animation="border" size="sm" /> : 'Analyse Property'}
            </Button>
            {message && <Alert variant="secondary" className="mt-3">{message}</Alert>}
            </Form>    
            </div>
          </div>      
        ) : (
          <>
            <p>Login to save your viewed properties</p>
            <Button 
              variant="primary" 
              style={{
                backgroundColor: agentDetails ? agentDetails.agent_button_colour : '#007bff',
                borderColor: agentDetails ? agentDetails.agent_button_colour : '#007bff'
              }} 
              onClick={() => setModalShow(true)}
            >
              Login
            </Button>
          </>
        )}
      </div>
    ) : (
      <Row>
        {propertiesList.map((property, index) => (
          <Col key={index} md={6} className="mb-4">
            <Card 
              style={{ 
                cursor: 'pointer', 
                display: 'flex', 
                flexDirection: 'column', 
                overflow: 'hidden', 
                height: '100%' 
              }}
              onClick={() => handleCardClick(property.full_slug, property.valuation_id)}
            >
              <div style={{ 
                width: '100%', 
                borderTopLeftRadius: '0.25rem', 
                borderTopRightRadius: '0.25rem', 
                overflow: 'hidden' 
              }}>
                <Carousel controls={false} indicators={false} interval={null} style={{ height: '200px' }}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={property.photo_url_full || 'https://realestate-reports-assets.s3.us-west-2.amazonaws.com/static_assets/no-image.jpg'}
                      alt={`Slide ${index}`}
                      style={{ height: '200px', objectFit: 'cover' }}
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Card.Title style={{ fontSize: isMobile ? '1.1rem' : '1.3rem' }}>{property.address_text_short}</Card.Title>
                <Card.Text style={{ marginTop: '7px'}}>
                <div style={{ marginTop: '0px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                  
                  <div style={{ }}>
                    <FaBed /> {property.bedrooms || 'N/A'} <span style={{ marginRight: '10px' }}></span>
                    <FaBath /> {property.bathrooms || 'N/A'} <span style={{ marginRight: '10px' }}></span>
                    <FaCar /> {property.carspaces || 'N/A'} <span style={{ marginRight: '10px' }}></span>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    
                      {property.property_type !== 'House' && property.property_type !== 'Apartment' && property.property_type}
                      {property.property_type === 'House' && <span style={{ marginRight: '15px' }}> <FaHome style={{ }} /> House</span>} 
                      {property.property_type === 'Apartment' && <span style={{ marginRight: '15px' }}> <FaBuilding style={{ }} /> Apartment </span>} 
                    
                    <span >
                      {property.land_area && (
                        <>
                          <FaTape style={{ marginBottom: '2px'}} /> {property.land_area} sqm
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </Card.Text>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              {/* <div> */}
                {type === 'valuations' && (
                  <LiveValuation property={property} agentDetails={agentDetails} />
                )}
                <Button 
                  variant="primary" 
                  onClick={() => handleCardClick(property.full_slug, property.valuation_id)}
                  style={{ 
                    backgroundColor: agentDetails.agent_colour, 
                    color: agentDetails.agent_button_colour, 
                    borderColor: agentDetails.agent_colour, 
                    width: '100%', 
                    alignSelf: 'flex-end',
                    marginTop: '5px'
                  }}
                >
                {type === 'valuations' && (
                  <>
                  <FaEdit style={{ marginRight: '5px', marginBottom: '3px' }} />
                  Edit Valuation
                  </>
                )}
                {type === 'properties' && (
                  <>
                  <FaCalculator style={{ marginRight: '5px', marginBottom: '3px' }} />
                  Start Valuation
                  </>
                )}
                </Button>
              </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    )
  );

  const LiveValuation = ({ property, agentDetails }) => {
    const [valuation, setValuation] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchValuation = async () => {
        const liveValuation = await fetchLiveValuation(property);
        setValuation(liveValuation);
        setLoading(false);
      };
      fetchValuation();
    }, [property]);

    if (loading) {
      return (
        <div style={{ minHeight: '65px', marginBottom: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ fontSize: '0.8rem' }}>Refreshing valuation</p>
          <Spinner animation="border" />
        </div>
      )
    }

    return (
      <div style={{ width: '100%', minHeight: '75px' }}>
      <ValueDisplayGroup
        lowerValue={formatCurrency(valuation.valuation_lower)}
        midValue={formatCurrency(valuation.valuation)}
        upperValue={formatCurrency(valuation.valuation_upper)}
        midColor={agentDetails.agent_button_colour}
        showTitle={false}
      />
      </div>
    );
  };

  return (
    <>
      {agentDetails && (
        <div>
          <Container style={{ padding: '10px', marginBottom: isMobile ? '40px' : '0px', maxWidth: '900px' }}>
            <Tabs activeKey={tabKey} variant='underline' onSelect={(k) => setTabKey(k)} className="mb-3" style={{ marginTop: isMobile ? '10px' : '30px' }}>
              <Tab eventKey="my-valuations" title={renderTabTitle("Saved Valuations")} style={{ marginBottom: isMobile ? '10px' : '0px'}}>
                <Row className="align-items-center mb-3">
                  <Col xs={12} style={{ marginTop: '1rem' }}>
                    <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>My Saved Valuations</h5>
                  </Col>
                  <Col xs={12} style={{ marginTop: '1rem' }}>
                  <p>Your saved valuations, complete with the property details you provided. Any adjustments you made to attributes such as bedrooms, bathrooms, or land area will be reflected here.</p>
                  </Col>
                </Row>
                {valuations && renderProperties(valuations, 'valuations')}
              </Tab>
              <Tab eventKey="recent" title={renderTabTitle("Recently Viewed")} style={{ marginBottom: isMobile ? '10px' : '0px'}}>
                <Row className="align-items-center mb-3">
                  <Col xs={12} style={{ marginTop: '1rem' }}>
                    <h5 style={{ fontSize: '1.5rem', fontWeight: '400' }}>Recently Viewed Properties</h5>
                  </Col>
                  <Col xs={12} style={{ marginTop: '1rem' }}>
                    <p>These are the properties you have recently viewed, analysed, or requested reports on.</p>
                  </Col>            
                </Row>
                {properties && renderProperties(properties, 'properties')}
              </Tab>
            </Tabs>
          </Container>
        </div>
      )}
      {agentDetails && (
        <SignInModal 
          show={modalShow} 
          onHide={() => setModalShow(false)}
          onSuccess={handleModalSuccess}
          agentDetails={agentDetails}
        />
      )}
    </>
  );
}

export default MyProperties;