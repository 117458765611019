import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import MainNav from '../components/MainNav';
import { debounce } from 'lodash';
import axios from 'axios';
import './Styles.css';
import { handleTokenAndPostRequest } from '../utils/utils';

function PropertySearch() {
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';
  const [agentDetails, setAgentDetails] = useState(() => {
    const storedAgentDetails = sessionStorage.getItem('agentDetails');
    return storedAgentDetails ? JSON.parse(storedAgentDetails) : null;
  });
  const [email, setEmail] = useState('');
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [message, setMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectKey, setSelectKey] = useState(0); 
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const param_token = params.get('token');

  useEffect(() => {
    const storedSlug = sessionStorage.getItem('landing_page_slug');
    if (!agentDetails || storedSlug !== agent_slug) {
      setIsLoading(true);
      const fetchAgentDetails = async () => {
        const url = `${window.api_host}/agency`;

        try {
          const response = await handleTokenAndPostRequest(
            url,
            {
              agent_slug: agent_slug,
            },
            {
              'Content-Type': 'application/json',
            }
          );

          const data = response.data;
          setAgentDetails(data);
          sessionStorage.setItem('agentDetails', JSON.stringify(data));
          sessionStorage.setItem('landing_page_slug', agent_slug);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchAgentDetails();
    } else {
      setIsLoading(false);
    }
  }, [agent_slug, agentDetails]);

  useEffect(() => {
    if (agentDetails) {
      document.title = `${agentDetails.agency_name_short} Property Report`;
      document.body.style.backgroundImage = `url(${agentDetails.landing_background_url})`;
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundPosition = 'center center';
      document.body.style.backgroundAttachment = 'fixed';
      document.body.style.backgroundSize = 'cover';
      setEmail(agentDetails.user_email);
    }

    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [agentDetails]);

  const loadProperties = debounce((inputValue, callback) => {
    axios.get(`${window.api_host}/property-search?q=${inputValue}`)
      .then(response => {
        const options = response.data.map(property => ({
          label: property.address_text,
          value: property.id,
          slug: property.slug
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error loading property options:', error);
        callback([]);
      });
  }, 500);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !selectedProperty) {
      setMessage('Please submit your email and select a property to receive the report.');
      return;
    }
    setIsUpdating(true);
  
    try {
      const response = await handleTokenAndPostRequest(
        `${window.api_host}/send-property-report`,
        {
          agent_slug: agent_slug,
          email,
          property_slug: selectedProperty.slug,
          property_id: selectedProperty.value,
          agency_id: agentDetails.agency_id,
          agent_id: agentDetails.agent_id,
          report_type: 'property_report',
        }
      );
  
      setIsUpdating(false);
  
      if (response.data.status === 'success') {
        setSelectKey(prevKey => prevKey + 1);
        setMessage('Your property report will be sent to your email shortly.');
      } else {
        setMessage('There was an error sending the property report. Please try again.');
      }
    } catch (error) {
      setIsUpdating(false);
      console.error('Error:', error);
      setMessage('There was an error sending the property report. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div>

      <Container className="agent-page" style={{ maxWidth: '600px' , marginTop: '120px' }}>
        <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>Download your instant property report</h1>
          <p>Enter your email and search for your property to receive a detailed report with valuation, time on market, history and comparable property data.</p>
          </Col>
        </Row>
        <Form className="w-100" onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Col>
                <Form.Control className="w-100" type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
            </Col>
          </Row>
          <Row className="mb-2">
              <Col>
                  <AsyncSelect
                      key={selectKey}
                      cacheOptions
                      defaultOptions
                      loadOptions={loadProperties}
                      onChange={setSelectedProperty}
                      value={selectedProperty}
                      noOptionsMessage={() => null}      
                      placeholder="Search property address"
                      openMenuOnClick={false}
                      styles={{
                          control: (provided) => ({ 
                              ...provided, 
                              borderRadius: '0.375rem', 
                              textAlign: 'left', 
                          }),
                          placeholder: (provided) => ({ 
                              ...provided, 
                              textAlign: 'left',
                          }),
                          menu: (provided) => ({ 
                              ...provided, 
                              textAlign: 'left',
                          }),
                          singleValue: (provided) => ({
                              ...provided, 
                              textAlign: 'left',
                          }),
                      }}
                  />
              </Col>
          </Row>
          <Button variant="primary" type="submit" className="w-100 submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }} disabled={isUpdating}>
            {isUpdating ? <Spinner animation="border" size="sm" /> : 'Send me my property report'}
          </Button>
          {message && <Alert variant="secondary" className="mt-3">{message}</Alert>}
          </Form>
      </Container>
    </div>
  );
}

export default PropertySearch;