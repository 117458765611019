import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Spinner, Alert } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import axios from 'axios';
import './Styles.css';
import { handleTokenAndPostRequest } from '../utils/utils';

function PropertySearchLive() {
  const { agent_slug: initial_agent_slug } = useParams();
  const agent_slug = initial_agent_slug || 'property-insights';
  const [agentDetails, setAgentDetails] = useState(() => {
    const storedAgentDetails = sessionStorage.getItem('agentDetails');
    return storedAgentDetails ? JSON.parse(storedAgentDetails) : null;
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [message, setMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectKey, setSelectKey] = useState(0); 
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const param_token = params.get('token');

  useEffect(() => {
    const storedSlug = sessionStorage.getItem('landing_page_slug');
    if (!agentDetails || storedSlug !== agent_slug) {
      setIsLoading(true);
      const fetchAgentDetails = async () => {
        const url = `${window.api_host}/agency`;

        try {
          const response = await handleTokenAndPostRequest(
            url,
            {
              agent_slug: agent_slug,
            },
            {
              'Content-Type': 'application/json',
            }
          );

          const data = response.data;
          setAgentDetails(data);
          sessionStorage.setItem('agentDetails', JSON.stringify(data));
          sessionStorage.setItem('landing_page_slug', agent_slug);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchAgentDetails();
    } else {
      setIsLoading(false);
    }
  }, [agent_slug, agentDetails]);

  useEffect(() => {
    const storedSlug = sessionStorage.getItem('landing_page_slug');
    if (agentDetails && storedSlug === agent_slug) {
      document.title = `${agentDetails.agency_name_short} Property Report`;
      document.body.style.backgroundImage = `url(${agentDetails.landing_background_url})`;
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.backgroundPosition = 'center center';
      document.body.style.backgroundAttachment = 'fixed';
      document.body.style.backgroundSize = 'cover';
    }

    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [agentDetails]);

  const loadProperties = debounce((inputValue, callback) => {
    axios.get(`${window.api_host}/property-search?q=${inputValue}`)
      .then(response => {
        const options = response.data.map(property => ({
          label: property.address_text,
          value: property.id,
          slug: property.slug
        }));
        callback(options);
      })
      .catch(error => {
        console.error('Error loading property options:', error);
        callback([]);
      });
  }, 500);

  const handlePropertyChange = (selectedOption) => {
    setSelectedProperty(selectedOption);
    if (selectedOption) {
      const property_id = selectedOption.value;
      const slug = selectedOption.slug;
      const agentSlug = agentDetails.landing_page_slug;
      console.log('Property selected:', property_id, slug, agentSlug);
      navigate(`/property-profile/${slug}-${property_id}/${agentSlug}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedProperty) {
      setMessage('Please select a property to view the report.');
      return;
    }
    // redirect the user to the Property Page
    const property_id = selectedProperty.value;
    const slug = selectedProperty.slug;
    const agentSlug = agentDetails.landing_page_slug;
    
    navigate(`/property-profile/${slug}-${property_id}/${agentSlug}`);
  };

  if (isLoading) {
    return (
      <div style={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)' 
      }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div>

      <Container className="agent-page" style={{ maxWidth: '600px' , marginTop: '120px' }}>
        <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>Do your own appraisal for your property!</h1>
          <p>Search for your property to do your own property appraisal and view detailed analysis with valuation, time on market, history and comparable property data.</p>
          </Col>
        </Row>
        <Form className="w-100" onSubmit={handleSubmit}>
          <Row className="mb-2">
              <Col>
                  <AsyncSelect
                      key={selectKey}
                      cacheOptions
                      defaultOptions
                      loadOptions={loadProperties}
                      onChange={handlePropertyChange}
                      value={selectedProperty}
                      noOptionsMessage={() => null}      
                      placeholder="Search property address"
                      openMenuOnClick={false}
                      styles={{
                          control: (provided) => ({ 
                              ...provided, 
                              borderRadius: '0.375rem', 
                              textAlign: 'left', 
                          }),
                          placeholder: (provided) => ({ 
                              ...provided, 
                              textAlign: 'left',
                          }),
                          menu: (provided) => ({ 
                              ...provided, 
                              textAlign: 'left',
                          }),
                          singleValue: (provided) => ({
                              ...provided, 
                              textAlign: 'left',
                          }),
                      }}
                  />
              </Col>
          </Row>
          <Button variant="primary" type="submit" className="w-100 submit-btn enquiry-submit" style={{ backgroundColor: agentDetails.agent_button_colour, borderColor: agentDetails.agent_button_colour }} disabled={isUpdating}>
            {isUpdating ? <Spinner animation="border" size="sm" /> : 'View my property analysis'}
          </Button>
          {message && <Alert variant="secondary" className="mt-3">{message}</Alert>}
          </Form>
      </Container>
    </div>
  );
}

export default PropertySearchLive;